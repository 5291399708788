import React from 'react'

import preloader from '../../img/preloader_transparent.inline.svg'
import s from './Preloader.module.css'

const Preloader = (_) => {
  return (
    <div className={s.preloader}>
      <div dangerouslySetInnerHTML={{ __html: preloader }} />
    </div>
  )
}

export default Preloader
