import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Widget = styled.div`
  &&& {
    @media (max-width: 980px) {
      padding: 0 5px;
      background-image: none;
      background-color: transparent;
      border-left: 1px solid #cbcfe2;
    }
  }
`

export const Quantity = styled.div`
  &&&& {
    @media (max-width: ${({ theme }) => (theme.isExp ? 1180 : 980)}px) {
      order: 2;
      font-size: 2.3vw;
    }

    @media (max-width: 980px) {
      font-size: 17px;
    }
  }
`

export const Unit = styled.div`
  &&&& {
    @media (max-width: ${({ theme }) => (theme.isExp ? 1180 : 980)}px) {
      width: calc(100% - 18px);
      margin-right: 0;
    }

    @media (max-width: 980px) {
      width: 100%;
    }
  }
`

export const Title = styled.div`
  &&&& {
    @media (max-width: ${({ theme }) => (theme.isExp ? 1180 : 980)}px) {
      order: 1;
      margin-bottom: 10px;
      margin-right: 0;
    }

    @media (max-width: 980px) {
      margin-bottom: 0;
    }

    @media (max-width: 850px) {
      > span {
        display: none;
      }
    }
  }
`

export const MoreLink = styled(Link)`
  &&&& {
    @media (max-width: ${({ theme }) => (theme.isExp ? 1500 : 1300)}px) {
      width: 16px;
      height: 16px;
    }

    @media (max-width: 980px) {
      background-position: left bottom;
    }

    > * {
      @media (max-width: ${({ theme }) => (theme.isExp ? 1500 : 1300)}px) {
        display: none;
      }
    }
  }
`
