import React, { useContext } from 'react'

import { appContext } from '../../store/app'

import logo from '../../img/logo.png'
import en from './img/en.png'
import ru from './img/ru.png'
import './AuthHeader.scss'

export const AuthHeader = () => {
  const { lang, setLang, langStrings } = useContext(appContext)
  const changeLang = () => setLang(lang === 'en' ? 'ru' : 'en')

  const __ = langStrings?.Auth

  const flagSrc = lang === 'en' ? en : ru

  return (
    <header className="auth-header">
      <div className="auth-container auth-header__wrapper">
        <img src={logo} alt="gorod" className="auth-header__logo" />
        <p className="auth-header__title">GOROD L.E.S.</p>
        <div className="auth-header__links">
          <div style={{ cursor:'pointer' }} title={__.tooltip} onClick={changeLang}>
            <img alt={lang} src={flagSrc} />
          </div>
          <a
            href="https://gorod.denistiaglin.com/"
            className="auth-header__this-link"
            rel="noopener norefferer"
          >
            {__.main}
          </a>
        </div>
      </div>
    </header>
  )
}
