import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Router } from 'react-router-dom'

import history from 'history.js'
import AccountLocked from 'components/AccountLocked/AccountLocked'
import LoadingRoutes from './LoadingRoutes'
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import { byteMap } from './consts'
import { appContext } from './store/app'
import { useAuth } from './hooks/useAuth.hook'

const App = (_) => {
  const { langStrings, userStateOfByte } = useContext(appContext)

  const isActual = useAuth()

  const getRoutes = () => {
    if (!isActual) {
      return <LoadingRoutes />
    }
    // For signedIn users servis looked like a litle faster
    if (
      (userStateOfByte | byteMap.ACTIVATED | byteMap.PHONE_CONFIRMED) ===
      byteMap.ALL_IS_TRUE
    ) {
      try {
        return (
          <PrivateRoutes
            langStrings={langStrings}
            activated={userStateOfByte & byteMap.ACTIVATED}
          />
        )
      } catch {
        return <PublicRoutes stateOfByte={userStateOfByte} />
      }
    }

    if (userStateOfByte & byteMap.IS_NOT_LOCKED || !userStateOfByte) {
      return <PublicRoutes stateOfByte={userStateOfByte} />
    }

    return <AccountLocked />
  }

  return (
    <>
      <Router history={history}>{getRoutes()}</Router>
    </>
  )
}

export default observer(App)
