import React from 'react'

import Button from 'components/Button/Button'
import appStore from 'store/app'
import s from './AccountLocked.module.css'

const AccountLocked = (_) => {
  const signOut = (_) => appStore.setAccountLocked(false)

  return (
    <div className={s.accountLocked}>
      <h1 className={s.message}>Your account locked</h1>
      <Button clickHandler={signOut} type="info">
        Sign Out
      </Button>
    </div>
  )
}

export default AccountLocked
