import React from 'react'
import TotalWidget from '../TotalWidget/TotalWidget'
import IncomeTransfers from '../IncomeTransfers/IncomeTransfers'
import LoadableError from '../LoadableError/LoadableError'
import { WhiteScreenErrorBoundary } from '../..'



const Fallback = (props) => {
  return (
    <div>
      <WhiteScreenErrorBoundary>
        <TotalWidget />
      </WhiteScreenErrorBoundary>
      <IncomeTransfers />
      <LoadableError />
    </div>
  )
}

export default Fallback
