import React, { useState } from 'react'

import appStore from 'store/app'

import { storage } from '../../../helpers'
import { ModalAskName } from '../ModalAskName'
import { ModalAskPhoneNumber } from '../ModalAskPhoneNumber'

export const AskingModals = () => {
  const [showModal, setShowModal] = useState(true)
  const toggleShowModal = () => setShowModal((prev) => !prev)

  const generateModalComponent = () => {
    if (appStore.sighnedAsAdmin) return null

    const modalAskName =
      !(appStore.userData.name || appStore.userData.lastname) &&
      !storage.getStorage('NOT_DISTURBED_NAME')
      
    if (modalAskName)
      return (
        <ModalAskName showModal={showModal} toggleModal={toggleShowModal} />
      )
    // else if (!appStore.userData.confirmed && (!appStore.userData.phone || !appStore.userData.isPhoneConfirmed))
    //   return (
    //     <ModalAskPhoneNumber
    //       showModal={showModal}
    //       toggleModal={toggleShowModal}
    //     />
    //   )
    return null
  }

  return generateModalComponent()
}
