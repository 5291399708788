import React from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import { ThemeProvider } from 'styled-components'

import appStore from 'store/app'
import s from './TotalWidget.module.css'
import { Widget, Quantity, Unit, Title } from './styled'
import { CardFabric } from '../Card/Card'
import { toJS } from 'mobx'
import { formatNumbers } from 'helpers'
import { CURRENCIES } from '../../store/app'
import SiteTransferNotification from '../SiteTransferNotification/SiteTransferNotification'
import WidgetNotification from '../WidgetNotification/WidgetNotification'

const TotalWidget = (props) => {
  const {
    isSidebarExpanded,
    langStrings,
    userSharesCount,
    userBalance,
    userIncome,
    userReferralsCount,
    notificationMarks,
    setNotificationMark,
    isExp,
    siteTransfer,
    cur
  } = appStore

  const __ = langStrings?.TotalWidget
  const params = useLocation()
  const cardsName = params.pathname

  const getCurrentCurTitle = _ => {
    switch (cur) {
      case CURRENCIES.RUB: return __.rub
      case CURRENCIES.USD: return __.usd
      default: return __.rub
    }
  }

  const cards = notificationMarks[cardsName] || []

  const onCardDelete = (id) => {
    const newCards = toJS(cards).filter(c => c._id !== id)
    setNotificationMark(cardsName, newCards)
  }

  return (
    <ThemeProvider theme={{ isExp: isSidebarExpanded }}>
      {siteTransfer && <SiteTransferNotification />}
      {/* <WidgetNotification /> */}

      <div className={s.totalWidget}>
        {props.hasOwnProperty('showTitle') && (
          <h2 className={s.mainTitle}>User Stats</h2>
        )}
        <div className={s.widgetsWrapper}>
          <Widget className={s.widget + ' ' + s.shares}>
            <Quantity className={s.quantity}>
              {formatNumbers({ amount: userSharesCount, integer: true })}
            </Quantity>
            <Unit className={s.unit}>{__.shares}</Unit>
            <Title
              className={s.title}
              dangerouslySetInnerHTML={{ __html: __.portfolio }}
            />
          </Widget>

          <Widget className={s.widget + ' ' + s.balance}>
            <Quantity className={s.quantity}>
              {formatNumbers({ amount: userBalance, isCurrency:true, fixed:2 })}
            </Quantity>
            <Unit className={s.unit}>{getCurrentCurTitle()}</Unit>
            <Title
              className={s.title}
              dangerouslySetInnerHTML={{ __html: __.balance }}
            />
          </Widget>

          <Widget className={s.widget + ' ' + s.income}>
            <Quantity className={s.quantity}>
              {formatNumbers({ amount: Math.floor(userIncome), isCurrency:true , fixed:2 })}
            </Quantity>
            <Unit className={s.unit}>{getCurrentCurTitle()}</Unit>
            <Title
              className={s.title}
              dangerouslySetInnerHTML={{ __html: __.income }}
            />
          </Widget>

          <Widget className={s.widget + ' ' + s.partners}>
            <Quantity className={s.quantity}>
              {formatNumbers({ amount: userReferralsCount })}
            </Quantity>
            <Unit className={s.unit}>{__.people}</Unit>
            <Title
              className={s.title}
              dangerouslySetInnerHTML={{ __html: __.partners }}
            />
          </Widget>
        </div>

        {isExp &&
          !!cards.length
          && toJS(cards)
            .map(card => CardFabric(params.pathname)({
              ...card,
              onClose: () => onCardDelete(card._id)
            }))}
      </div>
    </ThemeProvider>
  )
}

export default observer(TotalWidget)
