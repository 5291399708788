import React from 'react'
import s from './Button.module.css'

const Button = (props) => {
  const {
    type,
    opened,
    upperClass,
    children,
    fullWidth,
    isArrowDown,
    clickHandler,
    disabled,
    ...restProps
  } = props

  const getBtnType = (type) => {
    switch (type) {
    case 'info':
      return s.info
    case 'success':
      return s.success
    case 'danger':
      return s.danger
    case 'upload':
      return s.upload
    default:
      return ''
    }
  }

  return (
    <button
      className={
        s.button +
        ' ' +
        getBtnType(type) +
        (disabled ? ' ' + s.disabled : '') +
        (fullWidth ? ' ' + s.fullWidth : '') +
        (upperClass ? ' ' + upperClass : '') +
        (isArrowDown ? ' ' + s.btnArrowDown : '')
      }
      onClick={clickHandler}
      {...restProps}
    >
      {isArrowDown && (
        <span className={s.arrowDown + ' ' + (opened && s.opened)} />
      )}
      {children}
    </button>
  )
}

export default Button
