import React, { useContext, useState } from 'react'
import { cn } from '@bem-react/classname'

import './Card.scss'
import { formatNumbers, useTimer } from '../../helpers'

import { appContext, CURRENCIES } from '../../store/app'

const cnCard = cn('Card')
/**
 * @param {{
 *  title: string
 *  text: string
 *  className: string
 *  action: {
 *      text: string
 *      link: string
 *  }
 * onClose: function
 * }} Объект с данными
 * @returns {React.Component}
 */
export const Card = ({
  title,
  text,
  className,
  action,
  onClose,
  addon,
  ...other
}) => {
  const [cardCn, setCardCn] = useState(cnCard([className]))

  const handleClose = (e) => {
    e.preventDefault()

    setCardCn(cnCard({ closed: true }, [className]))
    setTimeout(() => {
      onClose()
    }, 300)
  }

  return (
    <a
      className={cardCn}
      href={action.link}
      target="_blank"
      rel="noopener noreferrer"
      // {...other}
    >
      <h4 className={cnCard('Title')}>{title}</h4>
      <div className={cnCard('ActionButton')}>{action.text}</div>
      <p>{text}</p>
      <div className={cnCard('Close')} onClick={handleClose}>
        &times;
      </div>
      {addon.text && (
        <div className={cnCard('Addon', addon.mods)}>{addon.text}</div>
      )}
    </a>
  )
}

const BuySharesCard = (props) => {
  const timer = useTimer(props.timeoutAT, () => {
    // Трюк для красоты - так пользователь точно увиит анимацию скрытия
    setIsRed(true)
    setTimeout(() => props.onClose(), 2000)
  })
  const [isRed, setIsRed] = useState(false)

  const { langStrings,cur } = useContext(appContext)
  const __ = langStrings?.Cards

  return (
    <Card
      key={props.toString()}
      title={cur === CURRENCIES.USD ? props.valueUsd + '$' : props.valueUsd * 75 + '₽' }
      text={__.needPay}
      action={{
        link: '/crypto-pay/' + props._id,
      }}
      addon={{
        text: timer,
        mods: { isRed },
      }}
      {...props}
    />
  )
}

export const CardFabric = (type) => (props) => {
  switch (type) {
  case '/buy-shares':
    return <BuySharesCard {...props} />
  default:
    return <Card {...props} />
  }
}
