import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import appStore from 'store/app'
import history from '../../../history'

import { useForm } from '../../../hooks/useForm.hook'
import { backReq } from '../../../helpers'

import { storage } from '../../../helpers'
import ModalDecorator from '../ModalDecorator'
import { AskNameForm } from '../../Forms/AskNameForm'

export const ModalAskName = observer(({ showModal, toggleModal }) => {
  const { form, handleChange } = useForm({
    name: appStore?.userData?.name || '',
    lastname: appStore?.userData?.lastname || '',
  })
  const [checkbox, setCheckbox] = useState(false)
  const toggleCheckbox = () => setCheckbox((prev) => !prev)

  const __ = appStore.langStrings?.Modals?.askName

  const generateOptions = (type) => {
    switch (type) {
      case 'name':
        return {
          method: 'update',
          submethod: 'name',
          name: form.name.value,
        }
      default:
        return {
          method: 'update',
          submethod: 'lastname',
          lastname: form.lastname.value,
        }
    }
  }

  const fetchData = (options) => {
    appStore.setAppLoading(true)

    const notificationSuccessMsg =
      options?.submethod === 'name'
        ? appStore.langStrings.Success.changeFirstName
        : appStore.langStrings.Success.changeLastName

    const notificationErrorMsg =
      options?.submethod === 'name'
        ? appStore.langStrings.Errors.errorChangeFirstName
        : appStore.langStrings.Errors.errorChangeLastName

    backReq('/private/user', options)
      .then((res) => {
        if (res.status === 'success') {
          appStore.addNotification(
            'success',
            notificationSuccessMsg
          )
        } else {
          throw new Error()
        }
      })
      .catch((e) => {
        appStore.addNotification('error', notificationErrorMsg)
      })
      .finally(() => {
        appStore.setAppLoading(false)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      appStore.userData.name !== form.name.value ||
      appStore.userData.lastname !== form.lastname.value
    ) {
      closeHandler()
    }
    if (appStore.userData.name !== form.name.value) {
      fetchData(generateOptions('name'))
    }
    if (appStore.userData.lastname !== form.lastname.value) {
      fetchData(generateOptions('lastname'))
    }
  }

  const closeHandler = () => {
    checkbox && storage.setStorage('NOT_DISTURBED_NAME', 'true')
    toggleModal()
  }

  const handleLink = (e) => {
    e.preventDefault()
    closeHandler()
    history.push('/profile/me')
  }

  return (
    showModal && (
      <ModalDecorator isOpen={showModal} closeHandler={closeHandler}>
        <h3>{__.header}</h3>
        <p>
          {__.intro}
          <br />
          <br />
          {__.currentName}
          <b>{' ' + appStore.userData.phone}</b>
          <br />
        </p>
        <AskNameForm
          form={form}
          handleChange={handleChange}
          onSubmit={handleSubmit}
        />
        <label style={{ margin: '10px 0 12px 0' }}>
          <input
            style={{ marginRight: '8px' }}
            type="checkbox"
            onChange={toggleCheckbox}
            value={checkbox}
          />
          {__.doNotDisturbed}
        </label>
        <br />
        <br />
        <span style={{ fontSize: '.7em' }}>
          {__.epilog}:<br />
          <a
            href="/profile/me"
            onClick={handleLink}
            style={{ textDecoration: 'underline' }}
          >
            {appStore?.langStrings?.Sidebar?.profileAndSettings +
              '/' +
              appStore?.langStrings?.Profile?.user}
          </a>
        </span>
      </ModalDecorator>
    )
  )
})
