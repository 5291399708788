import { useEffect, useContext, useState } from 'react'
import { appContext } from '../store/app'

export const useAuth = () => {
  const { getUserData, setXchangeCourse, userData, setAskModalOpen, isUserLogin } = useContext(appContext)
  const [isActual, setIsActual] = useState(false)

  useEffect(() => {
    const getData = async () => {
      await getUserData()
      await setXchangeCourse()
    }

    if (isUserLogin && !isActual) {
      try {
        getData()
        setIsActual(true)
				
        if (userData && !(userData.name || userData.lastname)) {
          setAskModalOpen(true)
        }

      } catch {
        return 
      }
    } else {
      setIsActual(true)
    }

    return () => {}
  }, [getUserData, isActual, isUserLogin, setAskModalOpen, setXchangeCourse, userData])

  return isActual
}
