import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'

import intlTelInput from 'intl-tel-input'

import { useForm } from '../../../hooks/useForm.hook'
import { validatorPhoneExist, validatorPhoneInternational } from '../../../helpers'

import appStore from 'store/app'

export const AskPhoneNumberForm = observer(({ onSubmit, phone }) => {
  const phoneRef = useRef()
  
  const { form, handleChange } = useForm(
    { phone: phone || '+7' },
    { phone: [validatorPhoneExist, validatorPhoneInternational] },
  )

  const __ = appStore.langStrings

  useEffect(() => {
    intlTelInput(phoneRef.current, {
      preferredCountries: ['ru'],
      formatOnDisplay: true,
    })
  }, [phoneRef])

  const handleOnSubmitForm = (e) => {
    e.preventDefault()
    onSubmit(form.phone.value)
  }

  return (
    <form action="#" onSubmit={handleOnSubmitForm} className="ask-name-form">
      <input
        type="tel"
        name="phone"
        value={form.phone.value}
        required={true}
        onChange={handleChange('phone')}
        ref={phoneRef}
        placeholder={__.Placeholders.phone}
      />
      <input
        type="submit"
        disabled={!form.phone.validation.isValid}
        value={__.Modals.askPhoneNumber.receive}
      />
    </form>
  )
})
