import React from 'react'
import s from './Input.module.css'

const Input = (props) => {
  const { label, locked, upperClass, fullWidth, refAttr, error, ...restProps } =
    props
  const type = props.type || 'text'

  return (
    <div className={s.inputGroup + (upperClass ? ' ' + upperClass : '')}>
      {label && <label className={s.label}>{label}</label>}

      <input
        className={
          s.input +
          (fullWidth ? ' ' + s.fullWidth : '') +
          (locked ? ' ' + s.locked : '') +
          (error ? ' ' + s.error : '')
        }
        ref={refAttr || null}
        type={type}
        disabled={!!locked}
        {...restProps}
        
      />
    </div>
  )
}

export default Input
