import { useReducer } from 'react'

/**
 * @param {Array<(value) => ({
 *  isValid: boolean
 *  errorMsg: string
 * })>} validators
 * @param {*} value - значение для прггона через валидаторы
 * @returns {{
 *  isValid: boolean
 *  errorMsg: string[]
 * }} - true если все валидаторы прошли + массив с сообщениями об ошибках
 */
const applyAllValidators = (validators = [], value) =>
  validators.reduce(
    (res, validator) => {
      const { isValid: isCurrentValid, errorMsg: currentErrorMsg } = validator(value)

      return {
        isValid: res.isValid && isCurrentValid,
        errorMsg: !isCurrentValid
          ? [...res.errorMsg, currentErrorMsg]
          : res.errorMsg,
      }
    },
    { isValid: true, errorMsg: [] }
  )

/**
 * Хук для обработки значений форм
 * @todo внедрить его в глобальный объект формы
 * @param {объект формы} initialForm форма
 * @param {{[key]: function[]}} validators массив функций с валидаторами
 * @returns {{
 *  form: Record<string, {
 *    value: any
 *    validation: {isValid: boolean, errorMsg: Array<string>}
 *  }>
 *  handleChange: (key: string) => ((React.FormEventHandler))
 * }}
 */
export function useForm(initialForm, validators = {}) {
  const formWithRefs = Object.create(null)

  for (let key in initialForm) {
    formWithRefs[key] = {
      value: initialForm[key],
      validation: applyAllValidators(validators[key], initialForm[key]),
    }
  }

  const reducer = (state, action) => {
    if (action.type === 'input') {
      const { field, value } = action

      return {
        ...state,
        [field]: {
          value: value,
          validation: applyAllValidators(validators[field], value),
        },
      }
    }
  }

  const [form, dispatch] = useReducer(reducer, formWithRefs)

  const setField = (field, value) => {
    dispatch({
      type: 'input',
      field,
      value,
    })
  }

  const handleChange = (name) => (e) => {
    setField(name, e.target.value)
  }

  return { form, handleChange }
}
