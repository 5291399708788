import React, { useState, useRef } from 'react'
import { observer, useObservable } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import appStore from 'store/app'
import Breadcrumbs from './Breadcrumbs'
import { AnyQuestions, LastnameFull, LastnameShort, LanguageDropdown, CurrencyDropdown } from './styled'
import anonymous from './img/anonymous.png'
import { backUrl } from 'consts'
import { useOutsideClick } from 'helpers'
import en from './img/en.png'
import ru from './img/ru.png'
import USD from './img/USD.jpg'
import RUB from './img/RUB.jpg'
import s from './Header.module.css'
import { CURRENCIES } from '../../store/app'

const { setIsSidebarExpanded } = appStore



const Header = (_) => {
  const { isSidebarExpanded, langStrings } = appStore
  const { userData, setLang: baseSetLang, setCur: baseSetCur, lang, cur } = useObservable(appStore)
  const { name, lastname, photo } = userData || {}
  const __ = langStrings?.Header

  const showSidebar = _ => appStore.setIsSidebarExpanded(true)

  const [langDropdownVisible, setLangDropdownVisible] = useState(false)
  const [curDropdownVisible, setCurDropdownVisible] = useState(false)
  const toggleLangDropdown = _ => setLangDropdownVisible(!langDropdownVisible)
  const toggleCurDropdown = _ => setCurDropdownVisible(!curDropdownVisible)

  const setLang = (e) => {
    baseSetLang(e.target.dataset.lang)
  }
  
  const setCur = (e) => {
    baseSetCur(e.target.dataset.cur)
  }

  const getCurrentLangFlag = (_) => {
    switch (lang) {
      case 'ru':
        return ru
      case 'en':
        return en
      default:
        return en
    }
  }

  const getCurrentCurFlag = _ => {
    switch (cur) {
      case CURRENCIES.RUB: return RUB
      case CURRENCIES.USD: return USD
      default: return en
    }
  }

  const getCurrentCurTitle = _ => {
    switch (cur) {
      case CURRENCIES.RUB: return __.currencyR
      case CURRENCIES.USD: return __.currencyD
      default: return __.currencyD
    }
  }

  const langRef = useRef()
  useOutsideClick(langRef, (_) => setLangDropdownVisible(false))

  const curRef = useRef()
  useOutsideClick(curRef, _ => setCurDropdownVisible(false))

  useOutsideClick('.sidebarArea', _ => {
    if (window.innerWidth <= 850) {
      setIsSidebarExpanded(false)
    }
  })

  return (
    <ThemeProvider theme={{ isExp: isSidebarExpanded }}>
      <div className={s.header}>
        <Breadcrumbs />

        <div className={s.inner}>
          <div className={s.hamburger + ' sidebarArea'} onClick={showSidebar} />

          <div className={s.anyQuestions}>
            <AnyQuestions>
              {__.haveQuestions}&nbsp;
              <a href="mailto:gorod@denistiaglin.com" className={s.contact}>
                {__.contactUs}
              </a>
              .
            </AnyQuestions>
          </div>

          {userData ? (
            <div className={s.langCurrencyAndProfile}>

              <CurrencyDropdown className={s.languageAndCurrency} ref={curRef}>

                <div className={s.currentLangAndCurWrapper} onClick={toggleCurDropdown}>
                  <span className={s.currentLangAndCurTitle}>{getCurrentCurTitle()}</span>
                  <img className={s.flag} src={getCurrentCurFlag()} alt='' draggable='false' />
                </div>

                {curDropdownVisible ? (
                  <div className={s.dropdown}>
                    <div className={s.item} data-cur={CURRENCIES.RUB} onClick={setCur}>
                      {__.currencyR}
                      <img className={s.cFlag} data-cur={CURRENCIES.RUB}  src={RUB} alt='' draggable='false' />
                    </div>

                    <div className={s.item} data-cur={CURRENCIES.USD} onClick={setCur}>
                      {__.currencyD}
                      <img className={s.cFlag} data-cur={CURRENCIES.USD} src={USD} alt='' draggable='false' />
                    </div>
                  </div>
                ) : null}

              </CurrencyDropdown>

              <LanguageDropdown className={s.languageAndCurrency} ref={langRef}>
                <div className={s.currentLangAndCurWrapper} onClick={toggleLangDropdown}>
                  <span className={s.currentLangAndCurTitle}>{__.langCaption}</span>
                  <img className={s.flag} src={getCurrentLangFlag()} alt='' draggable='false' />
                </div>

                {langDropdownVisible ? (
                  <div className={s.dropdown}>
                    <div className={s.item} data-lang="ru" onClick={setLang}>
                      Русский
                      <img
                        className={s.ddFlag}
                        src={ru}
                        alt=""
                        draggable="false"
                      />
                    </div>

                    <div className={s.item} data-lang="en" onClick={setLang}>
                      English
                      <img
                        className={s.ddFlag}
                        src={en}
                        alt=""
                        draggable="false"
                      />
                    </div>
                  </div>
                ) : null}
              </LanguageDropdown>

              <Link to="/profile">
                <div className={s.profile}>
                  <span>
                    <span>
                      {(name || '').slice(0, 1).toUpperCase() +
                        (name || '').slice(1)}
                    </span>
                    &nbsp;
                    <LastnameFull>
                      {(lastname || '').slice(0, 1).toUpperCase() +
                        (lastname || '').slice(1)}
                    </LastnameFull>
                    <LastnameShort>
                      {(lastname || '').slice(0, 1).toUpperCase() + '.'}
                    </LastnameShort>
                  </span>

                  <img
                    className={s.avatar}
                    alt=""
                    draggable="false"
                    src={photo ? backUrl + photo : anonymous}
                  />
                </div>
              </Link>
            </div>
          ) : <div>loading...</div>}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default observer(Header)
