import React, { useContext, useState } from 'react'

import ModalDecorator, { ModalCard, ModalCardGroup } from '../ModalDecorator'
import Button from '@src/components/Button/Button'
import { appContext } from '@src/store/app'

import './CryptoModal.scss'

function CryptoModal(props) {
  const isOpen = props.isOpen
  const onClose = props.onClose

  const { availableCryptocurrencies } = useContext(appContext)

  const [selected, setSelected] = useState(null)
  const [temporarySelected, setTemporarySelected] = useState('')

  const handleSubmit = (e) => {
    props.onSubmit(selected)
    props.onClose()
  }

  return (
    <ModalDecorator isOpen={isOpen} closeHandler={onClose}>
      <h3>{props.title}</h3>
      <ModalCardGroup tag="form" onSubmit={handleSubmit}>
        {availableCryptocurrencies.map((item) => {
          return (
            <ModalCard
              key={item.id}
              selected={item.id === (temporarySelected || selected)}
              onClick={() => setSelected(item.id)}
              onMouseEnter={() => setTemporarySelected(item.id)}
              onMouseLeave={() => setTemporarySelected('')}
              icon={<div className="CryptoModal_icon">{item.symbol}</div>}
            >
              <div>{item.name[0].toUpperCase() + item.name.slice(1)}</div>
            </ModalCard>
          )
        })}
      </ModalCardGroup>
      <Button type="success" onClick={handleSubmit} disabled={!selected}>
        {props.btnValue}
      </Button>
    </ModalDecorator>
  )
}
export { CryptoModal }
