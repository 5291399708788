import React from 'react'
import GroupsSVG from '../SocIcons/img/groups.svg'
import SupportSVG from '../SocIcons/img/support.svg'
import './LinkModal.scss'

export const LinkModal = ({ isLinkMenuVisisble, langStrings }) =>{ 
  return(
    <div
      className={isLinkMenuVisisble? 'linkMenu opened' : 'linkMenu closed'}
    >
      <a
        href={langStrings?.Auth?.tgSupport || 'https://t.me/WeterProjectBot'}
        rel="noopener noreferrer"
        target="_blank"
        className="linkMenu-link"
      >
        <img src={GroupsSVG} alt="Official Group" />
        <span>Official Group</span>
      </a>
      <a
        href={'https://t.me/WETER_SUPPORT'}
        className="linkMenu-link"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={SupportSVG} alt="Support" />
        <span>Support</span>
      </a>
    </div>
  )
}