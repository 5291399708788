import styled from 'styled-components'

export const LanguageDropdown = styled.div`
  &&&&& {
    @media (max-width: 950px) {
      margin-right: 20px;

      > span {
        @media (max-width: 751px) {
          display: none;
        }
      }
    }

    @media (max-width: 550px) {
      margin-right: 10px;
    }
  }
`

export const CurrencyDropdown = styled.div`&&&&& {
  @media (max-width: 950px) {
    margin-right: 20px;

    > span {
      @media(max-width: 751px) {
        display: none;
      }
    }
  }

  @media (max-width: 550px) {
    margin-right: 10px;
  }
}`

export const AnyQuestions = styled.div`
  @media (max-width: 850px) {
    display: none;
  }
`

export const LastnameFull = styled.div`
  display: inline-block;

  @media (max-width: 850px) {
    display: none;
  }
`

export const LastnameShort = styled.div`
  display: none;

  @media (max-width: 850px) {
    display: inline-block;
  }
`
