import styled from 'styled-components'

export const FooterWrapper = styled.div`
  && {
    @media (max-width: ${({ theme }) => (theme.isExp ? 1050 : 850)}px) {
      align-items: flex-start;
    }

    @media (max-width: 520px) {
      flex-wrap: wrap;
    }
  }
`

export const ContactsAndTrademark = styled.div`
  && {
    @media (max-width: ${({ theme }) => (theme.isExp ? 1050 : 850)}px) {
      > * {
        width: 100%;
      }
    }

    @media (max-width: 350px) {
      font-size: 11px;
    }
  }
`
