import React from 'react'

import './AskNameForm.scss'

import appStore from '../../store/app'

export const AskNameForm = ({ form, handleChange, onSubmit }) => {
  return (
    <form action="#" onSubmit={onSubmit} className="ask-name-form">
      <label>
        {appStore.langStrings?.Placeholders?.name}
        <input
          type="text"
          value={form.name.value}
          onChange={handleChange('name')}
        />
      </label>
      <br />
      <label>
        {appStore.langStrings?.Placeholders?.lastname}
        <input
          type="text"
          value={form.lastname.value}
          onChange={handleChange('lastname')}
        />
      </label>
      <input type="submit" value={appStore.langStrings?.Placeholders?.save} />
    </form>
  )
}
