import React from 'react'
import { cn } from '@bem-react/classname'

import './ModalDecorator.scss'

/**
 * General module for creating modal Windows
 * @param {{
 *  closeHandler: (() => void)
 *  isOpen: boolean
 * }}
 * Поддерживается глобальными классами: \
 * .modal_close: Кнопка закрытия модалки \
 * .amount: Красивое значение суммы сверху для финансовых модалок \
 * .type: Маленькая надпись снизу для описания действия \
 * h3(tag): Заголовок \
 * details: Таблица для контента \
 * details_item: Поочередно - левая и правая чейки таблицы
 */
const ModalDecorator = ({ children, closeHandler = () => {}, isOpen }) => {
  const cnModal = cn('modal')
  const cnInner = cn('inner')

  const closeModal = (e) => {
    if (!e.target.dataset.backdrop) {
      e.stopPropagation()
      return
    }

    closeHandler(e.target.dataset.backdrop)
  }

  return (
    <div
      className={cnModal({ closed: !isOpen })}
      onClick={closeModal}
      data-backdrop
    >
      <div className={cnInner()}>
        <div
          className="modal_close"
          aria-label="close modal"
          tabIndex="0"
          data-backdrop
        >
          &times;
        </div>
        {children}
      </div>
    </div>
  )
}

export default ModalDecorator

// mode
// TEL - generate link with phone number
// EMAIL - generate link with email
// other - only text
const modes = {
  TEL: 'TEL',
  EMAIL: 'EMAIL',
}

export function ModalDataRow({ name, value, mode }) {
  return (
    <>
      {(value || value === 0) && ( // value 0 is correct, but value '' or value null is flame me
        <>
          <div className="details_item">{name}</div>
          <div className="details_item">
            {mode ? SpecialFabric(mode, value) : value}
          </div>
        </>
      )}
    </>
  )
}

function PhoneLink(phone) {
  return <a href={'tel:' + phone}>{phone}</a>
}

function MailLink(email) {
  return <a href={'mailto:' + email}>{email}</a>
}

function SpecialFabric(mode, data) {
  switch (mode) {
  case modes.TEL:
    return PhoneLink(data)
  case modes.EMAIL:
    return MailLink(data)
  default:
    return <>{data}</>
  }
}

export function ModalCardGroup(props) {
  const { tag, children, ...otherProps } = props

  if (tag) {
    return React.createElement(
      tag,
      { ...otherProps, className: 'card__group' },
      children
    )
  }
  return <div className="card__group">{children}</div>
}

export function ModalCard(props) {
  let modalCn = 'modal__card card'
  const { icon, selected, ...otherProps } = props

  if (selected) {
    modalCn += ' card_selected'
  }

  return (
    <div className={modalCn} {...otherProps}>
      {icon && <div className="card__icon">{icon}</div>}
      {props.children}
    </div>
  )
}
