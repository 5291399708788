import React from 'react'
import { observer } from 'mobx-react'

import TotalWidget from 'components/TotalWidget/TotalWidget'
import IncomeTransfers from 'components/IncomeTransfers/IncomeTransfers'
// import RequireMobile from '../RequireMobile/RequireMobile'

import s from './PageWrapper.module.css'
import { WhiteScreenErrorBoundary } from '../..'

const PageWrapper = ({ children, heading, wrapperClass }) => (
  <div className={wrapperClass}>
    <WhiteScreenErrorBoundary>
      <TotalWidget />
    </WhiteScreenErrorBoundary>
    <IncomeTransfers />
    <h2 className={'mainTitle ' + s.heading}>{heading}</h2>
    {children}
  </div>
)

export default observer(PageWrapper)
