import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import appStore from "../../store/app"
import Button from "../Button/Button"
import { transferStage } from "../../consts"

import s from "./SiteTransferNotification.module.css"
import Modal from "../Modal/Modal"

const SiteTransferNotification = () => {
  const { langStrings, siteTransferStage, loginToNewSite } = appStore
  const [open, setOpen] = useState(false)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(false)

  const isStageCompleted = siteTransferStage === transferStage.completed

  const __ = isStageCompleted
    ? langStrings.SiteTransferNotification
    : langStrings.SiteTransferNotificationInProgress

  const handleButton = async () => {
    setLoading(true)
    const res = await loginToNewSite()
    setOpen(true)
    setToken(res.data.token)
    setLoading(false)
  }

  return (
    <>
      <div className={s.SiteTransferNotification}>
        <h2>{__.header}</h2>
        {__.text}
        {isStageCompleted && (
          <Button type="info" disabled={loading} onClick={handleButton}>
            {__.button}
          </Button>
        )}
      </div>
      <Modal isOpen={open}>
        <div className={s.modal}>
          <h2>{langStrings.Modals.askName.header}</h2>

          <Button type="info">
            <a href={`https://my.denistiaglin.com/-/imposter/${token}`}>
              {__.button}
            </a>
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default observer(SiteTransferNotification)
