import React from 'react'

import appStore from 'store/app'
import Modal from './Modal'
import Button from '../Button/Button'
import s from './YesNo.module.css'

const YesNoModal = ({ text, yesHandler, noHandler, isOpen = true }) => {
  const { langStrings } = appStore
  const __ = langStrings?.YesNoModal

  return (
    <Modal isOpen={isOpen}>
      <div className={s.inner}>
        <h2 className='mainTitle'>{__.confirmation}</h2>

        <p>{text}</p>

        <Button clickHandler={yesHandler} type='success'>
          {__.yes}
        </Button>
        <Button clickHandler={noHandler} type='danger'>
          {__.no}
        </Button>
      </div>
    </Modal>
  )
}

export default YesNoModal
