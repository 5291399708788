import React, { useContext, useRef } from 'react'
import { useOutsideClick } from '../../hooks/useOutsideClick.hook'
import { appContext } from '../../store/app'

import GroupsSVG from './img/groups.svg'
import SupportSVG from './img/support.svg'

import './SocialIcons.scss'

export const InstagramIcon = ({ active, tools }) => {
  const iconRef = useRef(null)

  useOutsideClick(iconRef, tools.resetActive)

  return (
    <>
      <div
        onClick={(e) => {
          tools.setThisActive()
        }}
        className="social-icon"
        id="instagram"
        ref={iconRef}
      >
        <div className={active ? 'svg-wrapper active' : 'svg-wrapper'}>
          <a
            href="https://www.instagram.com/weter_world/"
            rel="noopener noreferrer"
            target="_blank"
            className="social-link"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="30" 
              height="30" 
              viewBox="0 0 24 24"
            >
              <path fill='#ffffff' d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
            </svg>
          </a>
        </div>
      </div>
    </>
  )
}

export const TelegramIcon = ({ active, tools }) => {
  const iconRef = useRef(null)

  const{ langStrings } = useContext(appContext)

  useOutsideClick(iconRef, tools.resetActive)

  return (
    <>
      <div
        onClick={(e) => {
          tools.setThisActive()
        }}
        className="social-icon"
        id="telegram"
        ref={iconRef}
      >
        <div className={active ? 'svg-wrapper active' : 'svg-wrapper'}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 19.86 17.291"
          >
            <path d="M.35 8.291l4.576 1.708 1.771 5.7a.538.538 0 0 0 .855.257l2.551-2.079a.76.76 0 0 1 .927-.026l4.6 3.34a.539.539 0 0 0 .845-.326L19.845.654a.54.54 0 0 0-.722-.613L.343 7.286A.54.54 0 0 0 .35 8.291zm6.062.8l8.943-5.508a.156.156 0 0 1 .188.247l-7.381 6.861a1.53 1.53 0 0 0-.474.915l-.251 1.863a.231.231 0 0 1-.452.033l-.967-3.4a.9.9 0 0 1 .394-1.012z"></path>
          </svg>
        </div>
        {
          // active && (
          <div
            className={active ? 'social-links opened' : 'social-links closed'}
          >
            <a
              href={langStrings.Auth.tgSupport || 'https://t.me/WeterProjectBot'}
              rel="noopener noreferrer"
              target="_blank"
              className="social-link"
            >
              <img src={GroupsSVG} alt="Official Group" />
              <span>Official Group</span>
            </a>
            <a
              href={'https://t.me/WETER_SUPPORT'}
              className="social-link"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={SupportSVG} alt="Support" />
              <span>Support</span>
            </a>
          </div>
          // )
        }
      </div>
    </>
  )
}

export const WhatsupIcon = ({ active, tools }) => {
  const iconRef = useRef(null)

  useOutsideClick(iconRef, tools.resetActive)

  return (
    <>
      <div
        onClick={(e) => {
          tools.setThisActive()
        }}
        className="social-icon"
        id="whatsup"
        ref={iconRef}
      >
        <div className={active ? 'svg-wrapper active' : 'svg-wrapper'}>
          <a
            href="https://wa.me/971585304380"
            className="social-link"
            rel="noopener noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 16.846 16.927"
            >
              <path d="M14.392 2.46a8.39 8.39 0 0 0-13.2 10.121l-1.19 4.347 4.447-1.166a8.381 8.381 0 0 0 4.009 1.021 8.392 8.392 0 0 0 5.934-14.322zm-1.1 9.445a2.543 2.543 0 0 1-1.667 1.174 3.384 3.384 0 0 1-1.555-.1 14.18 14.18 0 0 1-1.408-.52 11.008 11.008 0 0 1-4.218-3.728 4.8 4.8 0 0 1-1.008-2.554 2.769 2.769 0 0 1 .864-2.06.907.907 0 0 1 .658-.309c.165 0 .329 0 .473.009s.355-.058.556.424.7 1.71.761 1.833a.454.454 0 0 1 .021.433 1.681 1.681 0 0 1-.247.412c-.124.144-.259.322-.37.433s-.252.257-.108.5a7.44 7.44 0 0 0 1.374 1.71 6.747 6.747 0 0 0 1.986 1.226c.247.124.391.1.535-.062s.617-.721.782-.968.329-.206.555-.124 1.44.68 1.687.8.412.185.473.288a2.064 2.064 0 0 1-.142 1.178z"></path>
            </svg>
          </a>
        </div>
      </div>
    </>
  )
}


