import React, { useContext } from 'react'
import { useForm } from 'hooks/useForm.hook'

import { InputPassword, InputLogin } from '../Inputs'
import { appContext } from '../../store/app'

const Entrance = ({ linkToRegister, linkToForget, signIn }) => {
  const { form, handleChange } = useForm({
    password: '',
    login: '',
  })

  const appStore = useContext(appContext)

  const _ = (appStore.langStrings && appStore.langStrings?.SignIn) || {}
  const placeholders =
    (appStore.langStrings && appStore.langStrings?.Placeholders) || {}

  const handleSubmit = (e) => {
    e.preventDefault()
    signIn({ login: form.login.value, password: form.password.value })
  }

  return (
    <>
      <div className="auth-main__header">{_.header}</div>
      <div className="auth-main__subheader">
        <br />
        {_.subheader}
      </div>
      <span className="auth-main__capture">{_.capture}</span>
      <form action="#" method="POST" onSubmit={handleSubmit}>
        <InputLogin
          value={form.login.value}
          onChange={handleChange('login')}
          placeholder={placeholders.username}
        />
        <InputPassword
          value={form.password.value}
          onChange={handleChange('password')}
          placeholder={placeholders.password}
        />
        <a href="/" onClick={linkToForget} className="auth-main__forget">
          {_.recoverPassword}
        </a>
        <input type="submit" className="auth-main__continue" value={_.header} />
      </form>
      <p className="auth-main__other">
        {_.dontHaveAnAccount}&nbsp;
        <a href="https://my.denistiaglin.com/">
          {_.toSignUp}
        </a>
      </p>
    </>
  )
}

export { Entrance }
