import React from 'react'

const Modal = ({ children, closeHandler, isOpen }) => {
  if (!closeHandler) closeHandler = (_) => {}
  const closeModal = ({
    target: {
      dataset: { backdrop },
    },
  }) => closeHandler(backdrop)

  return isOpen ? (
    <div className='modal' onClick={closeModal} data-backdrop>
      {children}
    </div>
  ) : null
}

export default Modal
