import React from 'react'
import { observer } from 'mobx-react-lite'

import appStore from 'store/app'
import s from './GlobalNotification.module.css'

const GlobalNotification = (_) => {
  const getTypeClass = (type) => {
    switch (type) {
    case 'error':
      return ' ' + s.error
    case 'success':
      return ' ' + s.success
    default:
      return ''
    }
  }

  if (!appStore.notifications.length) return null

  return (
    <div className={s.globalNotification}>
      {appStore.notifications.map(({ id, type, text }) => (
        <div
          className={s.notification + getTypeClass(type)}
          data-id={id}
          key={id}
        >
          {text}
        </div>
      ))}
    </div>
  )
}

export default observer(GlobalNotification)
