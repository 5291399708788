import React, { useState, useEffect, useContext, useCallback } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { cn } from '@bem-react/classname'

import appStore from 'store/app'
import { useHistory } from 'react-router-dom'
import { formatNumbers, backReq } from 'helpers'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import YesNoModal from 'components/Modal/YesNo'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import { CryptoModal } from 'components/Modal/CryptoModal/CryptoModal'
import s from './BuyShares.module.css'

import { appContext, CURRENCIES } from '../../store/app'
import Preloader from '../../components/Preloader/Preloader'
import { PaymentSystems } from '@src/components/PaymentSystems/PaymentSystems'

export const cnBuyShares = cn('BuyShares')

const blockedPackages = [
  "+5% 7.500 block 01.07.2022",
  "+5% 15.000 block 01.07.2022",
]

const { setAppLoading, addNotification } = appStore

const BuyShares = (_) => {
  const history = useHistory()

  const [yesNoModalVisible, setYesNoModalVisible] = useState(false)
  const [yesNoModalText, setYesNoModalText] = useState(false)

  const [modalVisible, setModalVisible] = useState(false)

  const {
    packages,
    fetchPackages,
    paymentSystems,
    payObject,
    setPropertiesOfPayObject,
    createPayment,
    allCryptoCurrencies,
    userBalance,
    adminRestrictedButton,
    siteTransfer,
    langStrings,
    currentPackage,
    cur,
    xchangeCourse,
  } = useContext(appContext)

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('xchangeCourse: ', xchangeCourse)
  }, [xchangeCourse])

  const __ = langStrings?.BuyShares

  // Выбор типа пакета: одиночный или в рассрочку
  const changeIsInstallment = (_) => {
    if (payObject.subtype === 'block') {
      setPropertiesOfPayObject({ subtype: 'installment', idBlock: null })
    } else {
      setPropertiesOfPayObject({ subtype: 'block', idBlock: null })
    }
  }

  // Выбор типа оплаты: одиночный или комбинированный (баланс + одна из платежек)
  const changeCombinedPayment = () => {
    if (payObject.type === 'whole') {
      setPropertiesOfPayObject({ type: 'combined' })
    } else {
      setPropertiesOfPayObject({ type: 'whole' })
    }

    if (payObject.submethod === 'internal') {
      setPropertiesOfPayObject({ submethod: null })
    }
  }

  // Выбор пакета
  const selectPackage = (e) => setPropertiesOfPayObject({ idBlock: e.currentTarget.dataset.id })

  const changeWithdrawAmountFromBalance = ({ target: { value } }) => {
    const amount = Number(value.replace(/\s/gi, ''))
    const amountRub = cur === CURRENCIES.USD ? amount * 75 : amount
    const MAX_VALUE = Math.min(userBalance, currentPackage.realCost || Infinity)

    if (amountRub > MAX_VALUE) {
      setPropertiesOfPayObject({
        amount: MAX_VALUE,
      })
      return
    }

    if (amountRub < 0 || isNaN(amountRub)) {
      setPropertiesOfPayObject({
        amount: 0,
      })
      return
    }

    setPropertiesOfPayObject({
      amount: amountRub,
    })
  }

  const selectPs = useCallback(
    (e) => {
      setPropertiesOfPayObject({
        submethod: e.currentTarget.dataset.psCode,
      })
    },
    [setPropertiesOfPayObject],
  )

  /** Создание рассрочки
   *
   * возвращает результат запроса на создание \
   * не обрабатывает ошибки
   */
  const createInstallment = async () => {
    setAppLoading(true)

    const res = await backReq('/private/installment', {
      method: 'add',
      idBlock: payObject.idBlock,
    })

    setAppLoading(false)

    return res
  }

  // Получение списка всех пакетов
  useEffect(() => {
    fetchPackages()
  }, [fetchPackages])

  // Getting gift size package
  // TODO: взятие пакетов без фильтрации по массиву
  const getGiftSize = (id) => {
    let p = toJS(packages)
    const gift = p.filter((block) => block._id === id && block.subtype === 'gift')[0]
    return gift.countShare || 0
  }

  /** Выбор метода для оплаты
   *
   * Создает рассрочку по необходимости \
   * Открывает модалку с правильным текстом
   */
  const selectPaymentHandler = async (e) => {
    let modalText = __.surePay

    if (payObject.subtype === 'installment') {
      const installment = await createInstallment()

      if (!installment.data) {
        addNotification(
          'error',
          'Произошла ошибка при создании рассрочки, попробуте повторить позже',
        )
      }

      setPropertiesOfPayObject({
        idInstallment: installment.data._id,
      })
    }

    if (payObject.submethod === 'binanceService') {
      setModalVisible(true)
      return
    }

    if (payObject.type === 'combined') {
      modalText = __.sureCombinedPayment
    }

    if (payObject.subtype === 'internal') {
      modalText = __.surePayWithBalance
    }

    setYesNoModalText(modalText)
    setYesNoModalVisible(true)
  }

  const onContinueWithCryptoCurrency = (cryptoCurrencyId) => {
    setPropertiesOfPayObject({
      currencyId: cryptoCurrencyId,
    })

    setAppLoading(true)
    createPayment()
      .then((res) => {
        if (res.status === 'success') {
          history.push('/crypto-pay/' + res.data.id)
        }
      })
      .catch((e) => {
        // TODO: обработка случаев ошибки
      })
      .finally(() => {
        setAppLoading(false)
      })
  }

  const getPaymentProps = () => {
    // собираем пропсы
    return {
      paymentSystems,
      __,
      payObject,
      selectPs,
      userBalance,
    }
  }

  /** Функция для оплаты
   *
   * Использует payObject для корректной оплаты \
   * Закрывает все модальные окна \
   * Обрабатывает ошибки создания платежей
   */
  async function pay() {
    setYesNoModalVisible(false)
    setModalVisible(false)

    const { status, data } = await createPayment()

    if (status !== 'success') {
      switch (status) {
        case 'invalidType':
          addNotification('error', __.invalidPaymentType)
          break
        case 'smallAmountPaySystem':
          addNotification('error', __.smallAmountPaySystem)
          break
        case 'smallAmount':
          addNotification('error', __.smallAmount)
          break
        default:
          addNotification('error', langStrings.Errors.unknowError)
      }
      return
    }

    if (payObject.submethod === 'internal') {
      addNotification('success', __.successfullyBought)
      return
    }

    if (payObject.submethod.includes('theMerchMoney')) {
      window.location.replace(data.checkout_url)
      return
    }

    const form = document.createElement('form')
    document.body.appendChild(form)
    for (const fieldName in data.redirect.data) {
      const input = document.createElement('input')
      input.name = fieldName
      input.type = 'hidden'
      input.value = data.redirect.data[fieldName]
      form.appendChild(input)
    }
    form.setAttribute('action', data.redirect.url)
    form.setAttribute('method', 'POST')
    form.submit()
  }

  // Закрыть модалку подтверждения оплаты балансом
  const yesNoModalClose = () => setYesNoModalVisible(false)

  // Закрытие модального окна Binance
  const modalClose = () => setModalVisible(false)

  if (!packages) return <Preloader />
  return (
    <PageWrapper wrapperClass={s.buyShares}>
      <div>
        {/* ШАГ ВЫБОРА ПАКЕТА */}
        <div className={s.stepPackage}>
          <h2 className={s.stepTitle}>{__.stepPackage}</h2>

          <p className={s.desc}>{__.choosePackage}</p>

          <label className={s.checkbox} htmlFor='isInstallment'>
            <input
              type='checkbox'
              id='isInstallment'
              checked={payObject.subtype === 'installment'}
              onChange={changeIsInstallment}
            />
            <span className={s.fakeCheckbox} />
            <span className={s.text}>{__.isInstallment}</span>
          </label>
          {/* Packages describe */}
          <div className={s.packagesWrapper}>
            {toJS(packages)
              .filter((pkg) => {
                return pkg.subtype === payObject.subtype
              })
              .filter((pkg) => !blockedPackages.includes(pkg.name))
              .map((p, i) => (
                <div
                  className={
                    s.package + ' ' + (payObject.idBlock === p._id ? ' ' + s.selected : '')
                  }
                  key={i}
                  data-id={p._id}
                  data-subtype={p.subtype}
                  onClick={selectPackage}
                >
                  <p className={s.title}>{__.packageInformation}</p>

                  <p className={s.subtitle}>
                    {__.price}:{' '}
                    {formatNumbers({
                      amount: p.costUSD,
                      isCurrency: true,
                      integer: true,
                    })}
                  </p>

                  <p className={s.subtitle}>
                    {__.sharesCount}: {formatNumbers({ amount: p.countShare })}
                  </p>

                  <p className={s.subtitle}>
                    {__.priceOfOneShare}:{' '}
                    {formatNumbers({
                      amount: p.costShareUSD,
                      isCurrency: true,
                      fixed: 5,
                    })}
                  </p>

                  {p.idGift &&
                    (!(payObject.subtype === 'installment') ? (
                      <p className={s.subtitle + ' ' + s.subtitleGift}>
                        {__.giftSize}: {formatNumbers({ amount: getGiftSize(p.idGift) })}
                      </p>
                    ) : (
                      <p className={s.subtitle}>
                        {__.giftSize}: {formatNumbers({ amount: getGiftSize(p.idGift) })}(
                        {__.giftavailable})
                      </p>
                    ))}
                </div>
              ))}
          </div>
        </div>

        {!siteTransfer && (
          <>
        {/* ШАГ ОПЛАТЫ */}
        <div className={s.stepPayment}>
          <h2 className={s.stepTitle}>{__.stepPayment}</h2>

          <p className={s.desc}>{__.choosePaymentSystem}</p>

          <p className={s.descSub}>{__.moneyLimits}</p>

          <label className={s.checkbox} htmlFor='combined'>
            <input
              type='checkbox'
              id='combined'
              checked={payObject.type === 'combined'}
              onChange={changeCombinedPayment}
            />
            <span className={s.fakeCheckbox} />
            <span className={s.text}>{__.payCombined}</span>
          </label>

          {payObject.type === 'combined' ? (
            <>
              {/* Баланс и поле ввода суммы для списания */}
              <div className={s.paymentSystemsWrapper + ' ' + s.separateBalance}>
                <Input
                  onChange={changeWithdrawAmountFromBalance}
                  value={formatNumbers({
                    amount: payObject.amount,
                    isInput: true,
                    isCurrency: true,
                    fixed: 2,
                  })}
                  upperClass={s.withdrawAmountFromBalance}
                  label={__.amountFromBalance}
                  type='text'
                />

                {/* Только баланс */}
                <PaymentSystems.OnlyBalance {...getPaymentProps()} />

                <div className={s.plus}>+</div>
              </div>

              {/* Все платежки кроме баланса */}
              <PaymentSystems.WithoutBalance {...getPaymentProps()} />
            </>
          ) : (
            <div className={s.paymentSystemsWrapper}>
              <PaymentSystems.All {...getPaymentProps()} />
            </div>
          )}
        </div>

        {/* Button Pay */}
        <div className={s.scrollWrapper}>
          <Button
            type='success'
            upperClass={s.btnPay}
            disabled={
              !payObject.submethod ||
              !payObject.idBlock ||
              adminRestrictedButton.includes('buy-shares')
            }
            onClick={selectPaymentHandler}
          >
            {__.pay}
          </Button>
        </div>
          </>
        )}
      </div>

      {modalVisible && (
        <CryptoModal
          title={__.chooseCoin}
          cryptoCurrencies={allCryptoCurrencies}
          btnValue={__.continuePayment}
          onSubmit={onContinueWithCryptoCurrency}
          isOpen={modalVisible}
          onClose={modalClose}
        />
      )}

      {/* Модалка с подтверждением действия */}

      <YesNoModal
        isOpen={yesNoModalVisible}
        text={yesNoModalText}
        yesHandler={pay}
        noHandler={yesNoModalClose}
      />
    </PageWrapper>
  )
}

export default observer(BuyShares)
