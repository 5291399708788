import React from 'react'
import { observer } from 'mobx-react'

import appStore from 'store/app'

import './style.scss'
import { useForm } from '../../../hooks/useForm.hook'

export const AskConfirmCodeForm = observer(({
  onSubmit,
  toPrevStage,
}) => {
  const _ = appStore.langStrings
  const { askForNewActivationCode, userData } = appStore

  const { form, handleChange } = useForm(
    {
      confirmCode: ''
    }
  )

  const handleOnSubmitForm = (e) => {
    e.preventDefault()
    onSubmit(form.confirmCode.value)
  }

  return (
    <form action="#" onSubmit={handleOnSubmitForm} className="ask-name-form">
      <p>
        {_?.Modals?.askPhoneNumber.phoneNumber}
        <b>{userData.phone}</b>
      </p>
      <button onClick={() => toPrevStage()}>
        {_?.Modals?.askPhoneNumber.change}
      </button>
      <br />
      <br />
      <br />
      <div className="centring-wrapper">
        <input
          type="text"
          maxLength="6"
          minLength="6"
          placeholder={_?.Placeholders?.enterCode}
          value={form.confirmCode.value}
          onChange={handleChange('confirmCode')}
          required={true}
          autoComplete="one-time-code"
          inputtype="numeric"
          className="confirm_code"
        />
        <div className="buttons-wrapper">
          <input type="submit" value={_?.Modals?.askPhoneNumber.send} />
          <button type="reset" onClick={() => askForNewActivationCode()}>
            {_?.Modals?.askPhoneNumber.sendAgain}
          </button>
        </div>
      </div>
    </form>
  )
})
