import React, { useState } from 'react'

import {
  TelegramIcon,
  WhatsupIcon,
  InstagramIcon,
} from '../SocIcons/SocialIcons'

import './AuthFooter.scss'

export const AuthFooter = () => {
  const [active, setActive] = useState('')

  const resetActive = () => {
    setActive('')
  }

  const setCurrentActive = (social) => () => {
    setActive(social)
  }

  return (
    <footer className="auth-footer auth-container">
      <div className="auth-footer__text">
        TIAGLIN HUB, WETER LLC; 
        UAE, DUBAI, DIP 1, W10<br/>
        gorod@denistiaglin.com; +971 58 530 4380<br/>
        All rights reserved © 2020 GOROD L.E.S.
      </div>
      <div className="auth-footer__links" style={{ pointerEvents: 'all' }}>
        <InstagramIcon
          active={active === 'instagram'}
          tools={{
            resetActive,
            setThisActive: setCurrentActive('instagram'),
          }}
        />
        <TelegramIcon
          active={active === 'telegram'}
          tools={{
            resetActive,
            setThisActive: setCurrentActive('telegram'),
          }}
        />
        <WhatsupIcon
          active={active === 'whatsup'}
          tools={{
            resetActive,
            setThisActive: setCurrentActive('whatsup'),
          }}
        />
      </div>
    </footer>
  )
}
