import React from 'react'
import PropTypes from 'prop-types'
import { cn } from '@bem-react/classname'
import logoPayMaster from './img/paymaster.png'
import logoCryptonator from './img/cryptonator.png'
import logoRobokassa from './img/robokassa.png'
import logoYandex from './img/yandex.png'
import logoAdvCash from './img/advcash.png'
import logoPayeer from './img/payeer.png'
import logoPerfectMoney from './img/perfectmoney.jpg'
import logoBinance from './img/binance.svg'
import logoCard from './img/card.jpg'
import './PaymentSystems.scss'
import { formatNumbers } from 'helpers'

const paymentSystemsCn = cn('PaymentSystems')

const getPsImg = (psCode) => {
  switch (psCode) {
  case 'payMaster':
    return logoPayMaster
  case 'cryptonator':
    return logoCryptonator
  case 'roboKassa':
    return logoRobokassa
  case 'yandex':
    return logoYandex
  case 'advcash':
    return logoAdvCash
  case 'payeer':
    return logoPayeer
  case 'perfectmoney':
    return logoPerfectMoney
  case 'binanceService':
    return logoBinance
  case 'theMerchMoney':
    return logoCard
  case 'theMerchMoneyRub':
    return logoCard
  default:
    return null
  }
}

const PaymentSystemsWithoutBalance = (props) => {
  let paymentSystems = props.paymentSystems.filter(
    (c) => c.psCode !== 'internal'
  )
  return (
    <>
      {paymentSystems.map((p, i) => (
        <div
          className={paymentSystemsCn('System', {
            selected: p.psCode === props.payObject.submethod,
          })}
          onClick={!p.outOfService ? props.selectPs : () => {}}
          key={i}
          data-ps-code={p.psCode}
        >
          <p className={paymentSystemsCn('Title')}>{p.title}</p>

          <div
            className={paymentSystemsCn('PaymentSystemImgWrapper', {
              disabled: p.outOfService,
            })}
            data-message={props.__.outOfService}
          >
            <img src={getPsImg(p.psCode)} draggable="false" alt="" />
          </div>
        </div>
      ))}
    </>
  )
}

const PaymentSystems = {
  OnlyBalance: (props) => (
    <div className={paymentSystemsCn()}>
      <PaymentSystemsOnlyBalance {...props} />
    </div>
  ),
  WithoutBalance: (props) => (
    <div className={paymentSystemsCn()}>
      <PaymentSystemsWithoutBalance {...props} />
    </div>
  ),
  All: (props) => (
    <div className={paymentSystemsCn()}>
      <PaymentSystemsWithoutBalance {...props} />
      <PaymentSystemsOnlyBalance {...props} choosable={true} />
    </div>
  ),
}

const PaymentSystemsOnlyBalance = (props) => {
  return (
    <div
      className={paymentSystemsCn(
        'System',
        {
          selected: props.payObject.submethod === 'internal' || props.payObject.type === 'combined',
        },
        [paymentSystemsCn('Balance')],
      )}
      onClick={props.choosable ? props.selectPs : () => {}}
      data-ps-code={'internal'}
    >
      <p className={paymentSystemsCn('Title')}>{props.__.yourBalance}</p>

      <p className={paymentSystemsCn('UserBalance')}>
        {formatNumbers({
          amount: props.userBalance,
          isCurrency: true,
          fixed: 2,
        })}
      </p>

      <div
        className={paymentSystemsCn('PaymentSystemImgWrapper')}
        data-message={props.__.outOfService}
      >
        <img src={getPsImg('internal')} draggable='false' alt='' />
      </div>
    </div>
  )
}

const propTypes = {
  paymentSystems: PropTypes.array,
  __: PropTypes.shape({
    outOfService: PropTypes.string,
  }),
  payObject: PropTypes.shape({
    submethod: PropTypes.string,
  }),
  selectPs: PropTypes.func.isRequired,
  userBalance: PropTypes.number.isRequired,
}

PaymentSystems.propTypes = propTypes
PaymentSystemsOnlyBalance.propTypes = propTypes
PaymentSystemsWithoutBalance.propTypes = propTypes

export {
  PaymentSystems,
  PaymentSystemsWithoutBalance,
  PaymentSystemsOnlyBalance,
}
