import React, { useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import qS from 'query-string'

import GlobalNotification from 'components/GlobalNotification/GlobalNotification'

import { AuthPage } from './pages/AuthPage/AuthPage'

import { appContext } from './store/app'
import { observer } from 'mobx-react-lite'
import { getCookie } from './helpers'

const PublicRoutes = observer(() => {
  const {
    loadLangStrings,
    langStrings,
    setWishPage,
    signInAsAdmin,
    signOut
  } = useContext(appContext)

  const nav = window.navigator
  const lang = getCookie('lang') || nav.language || nav.userLanguage
  const parsedLang = lang.split ? lang.split('-')[0] : null

  const qParams = qS.parse(window.location.search)
  const route = window.location
  const inviteParam = qParams.invite || qParams.ref || qParams.u

  useEffect(() => {
    if (!langStrings) {
      const langToLoad = ['ru', 'en'].includes(parsedLang) ? parsedLang : 'en'
      loadLangStrings(langToLoad)
    }
  })

  if (langStrings) return (
    <>
      <GlobalNotification />
      <Switch>
        <Route exact path="/auth" render={() => <AuthPage />} />
        <Route
          path="/enter/admin/:key"
          render={(props) => {
            signOut(() => signInAsAdmin(props.match.params.key))

            return <Redirect to="/auth" />
          }}
        />
        <Route
          render={(_) => {

            if (route.pathname !== '/auth' && route.pathname !== '/') {
              setWishPage(route)
            }

            return inviteParam ? (
              <Redirect to={`/auth?invite=${inviteParam}`} />
            ) : (
              <Redirect to="/auth" />
            )
          }}
        />
      </Switch>
    </>
  )
})

export default PublicRoutes
