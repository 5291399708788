import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'

import s from './Breadcrumbs.module.css'
import { appContext } from '../../store/app'

const Breadcrumbs = () => {
  const history = useHistory()
  const { langStrings } = useContext(appContext)

  const [breadcrumb, setBreadcrumb] = useState(
    langStrings.BreadCrumbs[history.location.pathname]
  )

  useEffect(() => {
    if (langStrings.BreadCrumbs[history.location.pathname] !== breadcrumb) {
      setBreadcrumb(langStrings.BreadCrumbs[history.location.pathname])
    }
  }, [breadcrumb, history.location.pathname, langStrings.BreadCrumbs])

  return (
    langStrings.BreadCrumbs && (
      <div className={s.breadcrumbs}></div>
    )
  )
}

export default observer(Breadcrumbs)
