import React, { useState, useRef } from 'react'
import { ThemeProvider } from 'styled-components'
import { observer } from 'mobx-react-lite'
import { LinkModal } from './LinkModal'

import appStore from 'store/app'
import { FooterWrapper, ContactsAndTrademark } from './styled'
import s from './Footer.module.css'
import { useOutsideClick } from '../../helpers'

const Footer = (_) => {
  const { isSidebarExpanded, langStrings } = appStore
  const __ = langStrings?.Footer

  const [isLinkMenuVisisble, setIsLinkMenuVisible] = useState(false)

  const iconRef = useRef(null)
  
  useOutsideClick(iconRef, () => setIsLinkMenuVisible(false))

  const toggleLinkModal = (e) => {
    e.preventDefault()
    setIsLinkMenuVisible(!isLinkMenuVisisble)
  }

  return (
    <ThemeProvider theme={{ isExp: isSidebarExpanded }}>
      <FooterWrapper className={s.footer}>
        <LinkModal 
          isLinkMenuVisisble={isLinkMenuVisisble}
          langStrings={langStrings}
        />
        <div className={s.socials}>
          <a
            href="https://www.instagram.com/weter_world"
            target="_blank"
            rel="noopener noreferrer"
            className={s.ig}
          >
            {' '}
          </a>
          <a
            onClick={ e => toggleLinkModal(e)}
            href="https://t.me/WETER_SUPPORT"
            target="_blank"
            rel="noopener noreferrer"
            className={s.tg}
            ref={iconRef}
          >
            {' '}
          </a>
          <a
            href="https://wa.me/971585304380"
            target="_blank"
            rel="noopener noreferrer"
            className={s.wa}
          >
            {' '}
          </a>
        </div>

        <ContactsAndTrademark className={s.contactsAndTrademark}>
          <div className={s.contacts}>
            <span>{__.address}</span>
            <span>
              <a href="mailto:gorod@denistiaglin.com">gorod@denistiaglin.com</a>
              {'; '}
              <a href="tel:+971585304380">+971 58 530 4380</a>
            </span>
          </div>

          <div className={s.trademark}>
            <span>{__.rightsReserved}.&nbsp;</span>
            <span>© {new Date().getFullYear()}, GOROD L.E.S.</span>
          </div>
        </ContactsAndTrademark>
      </FooterWrapper>
    </ThemeProvider>
  )
}

export default observer(Footer)
