import { Component } from 'react'

/**
 * Обертка для отлова ошибок
 * @property { React.Node } FallbackComponent - Компонент для отрисовки UI в случае ошибки
 * @property { Function } errorNotificate - вызывает сообщение об ошибке загрузки \
 * @returns Дочерний компонент в случае удачного вызова или FallbackComponent
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(e) {
    this.props.errorNotificate && this.props.errorNotificate()
  }

  render() {
    if (this.state.hasError) {
      return this.props.FallbackComponent
    }

    return this.props.children
  }
}

export default ErrorBoundary
