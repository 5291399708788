import React, { useContext } from 'react'
import classes from './LoadableError.module.css'
import { appContext } from '../../store/app'

const LoadableError = () => {
  const { langStrings } = useContext(appContext)
  const _ = langStrings?.Errors || {}
  return (
    <div className={classes.loadableError}>
      <div className={classes.loadableError__string}>{_.errorNetwork[0]}</div>
      <div className={classes.loadableError__string}>{_.errorNetwork[1]}</div>
    </div>
  )
}

export default LoadableError
