import React, { useEffect, useContext, useState } from 'react'
import { observer } from 'mobx-react'

import { appContext } from '@src/store/app'

import ModalDecorator from '../ModalDecorator'

import { AskPhoneNumberForm } from '../../Forms/AskPhoneNumberForm/AskPhoneNumberForm'
import { AskConfirmCodeForm } from '../../Forms/AskConfirmCodeForm/AskConfirmCodeForm'

import './style.scss'

export const ModalAskPhoneNumber = observer(({ showModal, toggleModal }) => {
  const {
    userData,
    langStrings,
    askForNewActivationCode,
    changePhoneNumber,
    sendActivationCode,
    getUserData,
  } = useContext(appContext)

  const [stage, setStage] = useState('phone')

  const __ = langStrings?.Modals.askPhoneNumber

  useEffect(() => {
    if (stage === 'code') {
      askForNewActivationCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage])

  const handleOnSubmitPhoneNumberForm = async (phone) => {
    if (userData?.phone !== phone) {
      const res = await changePhoneNumber(phone)
      if (res) {
        setStage('code')
        getUserData()
      }
    } else {
      setStage('code')
    }
  }

  const handleOnSubmitConfirmCodeForm = async (confirmCode) => {
    const res = await sendActivationCode(confirmCode)
    res && toggleModal()
  }

  return (
    showModal && (
      <ModalDecorator isOpen={showModal} closeHandler={toggleModal}>
        <h3>{__.header}</h3>
        <p className="modal-ask-phone-number__main">{__.main}</p>

        {stage === 'phone' ? (
          <AskPhoneNumberForm phone={userData?.phone} onSubmit={handleOnSubmitPhoneNumberForm} />
        ) : (
          <AskConfirmCodeForm
            onSubmit={handleOnSubmitConfirmCodeForm}
            toPrevStage={() => setStage('phone')}
          />
        )}
      </ModalDecorator>
    )
  )
})
