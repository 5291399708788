import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { default as NavLinkBase } from '../NavLink/NavLink'

import appStore from 'store/app'
import { ReactComponent as IconDashboard } from './img/icon_dashboard.inline.svg'
import { ReactComponent as IconBuyShares } from './img/icon_buy_shares.inline.svg'
import { ReactComponent as IconInstallments } from './img/icon_installments.inline.svg'
import { ReactComponent as IconPayoutsAndTransfers } from './img/icon_payouts_and_transfers.inline.svg'
import { ReactComponent as IconReferralProgram } from './img/icon_referral_program.inline.svg'
// import { ReactComponent as IconMarketing } from './img/icon_marketing.inline.svg'
import { ReactComponent as IconDocuments } from './img/icon_documents.inline.svg'
import { ReactComponent as IconSettings } from './img/icon_settings.inline.svg'
import { ReactComponent as IconStreaming } from './img/icon_streaming.inline.svg'
import { ReactComponent as IconSignOut } from './img/icon_signout.inline.svg'

import { useOutsideClick } from 'helpers'
import { throttling } from '../../helpers'
import s from './Sidebar.module.css'

const { setIsSidebarExpanded: setIsSidebarExpandedBase } = appStore

const Sidebar = (_) => {
  const { isSidebarExpanded, langStrings, notificationMarks } = appStore
  const getSidebarWidth = (isExpanded) => {
    if (window.innerWidth <= 850) {
      return 260
    } else {
      return isExpanded ? 260 : 60
    }
  }
  const MAX_SIDEBAR_WIDTH = 400
  const MIN_SIDEBAR_WIDTH = 60

  const setIsSidebarExpanded = (isExp) => {
    setIsSidebarExpandedBase(isExp)
  }

  const [width, setWidth] = useState(getSidebarWidth(isSidebarExpanded))
  const [isOnResize, setIsOnResize] = useState(false)

  const __ = langStrings?.Sidebar

  const toggleSidebar = (_) => {
    const prev = isSidebarExpanded
    setIsSidebarExpanded(!prev)
    setWidth(getSidebarWidth(!prev))
  }

  const signOut = (_) => appStore.signOut()

  useOutsideClick('.sidebarArea', (_) => {
    if (window.innerWidth <= 850 && !isOnResize) {
      setIsSidebarExpanded(false)
    }
  })

  const handleWindowResize = throttling(() => {
    if(window.innerWidth < 850) {
      setWidth(getSidebarWidth())
      setIsSidebarExpanded(false)
    } else {
      setIsSidebarExpanded(true)
    }
  }, 50)

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  const NavLink = (props) => {
    const handleClick = (e) => {
      if (window.innerWidth <= 850) {
        setIsSidebarExpanded(false)
      }
      typeof props.onClick === 'function' && props.onClick(e)
    }
    return (
      <NavLinkBase
        notificate={notificationMarks[props.to] || []}
        {...props}
        onClick={handleClick}
      />
    )
  }

  const handleMouseMove = throttling((e) => {
    const newWidth = e.clientX + 5

    if (newWidth > MAX_SIDEBAR_WIDTH) {
      setWidth(MAX_SIDEBAR_WIDTH)
      return
    }
    if (newWidth < MIN_SIDEBAR_WIDTH) {
      setWidth(MIN_SIDEBAR_WIDTH)
      return
    }

    setWidth(newWidth)

    if (newWidth < 140) {
      setIsSidebarExpanded(false)
    }
    if (newWidth > 160) {
      setIsSidebarExpanded(true)
    }
  }, 50)

  const handleMouseMoveCleanup = () => {
    setIsOnResize(false)
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseMoveCleanup)
  }

  const handleResizeStart = (e) => {
    setIsOnResize(true)
    document.addEventListener('mousemove', handleMouseMove, { passive: true })
    document.addEventListener('mouseup', handleMouseMoveCleanup)
  }

  return (
    <div
      className={
        s.sidebar +
        (!isSidebarExpanded ? ' ' + s.shrinked : '') +
        ' sidebarArea'
      }
      style={{ width }}
    >
      <div className={s.hamburgerAndLogo}>
        <div className={s.hamburger} onClick={toggleSidebar} />

        <div className={s.logo} />

        {isSidebarExpanded && (
          <div className={s.title}>
            <h2 className="mainTitle">GOROD</h2>

            <h2 className={'mainTitle ' + s.mainSubTitle}>
              <span>L</span>ogistic <span>E</span>co <span>S</span>ystem
            </h2>
          </div>
        )}
      </div>

      <div>
        <NavLink
          to={'https://denistiaglin.com/logistic'}
          title={__.dashboard}
          iconComponent={<IconDashboard />}
          isExpanded={isSidebarExpanded}
          isExternal={true}
        />
        <NavLink
          to="/buy-shares"
          title={__.buyShares}
          iconComponent={<IconBuyShares />}
          isExpanded={isSidebarExpanded}
        />
        <NavLink
          to="/investments"
          title={__.investments}
          iconComponent={<IconInstallments />}
          isExpanded={isSidebarExpanded}
        />
        <NavLink
          to="/payouts-and-transfers"
          title={__.payoutsAndTransfers}
          iconComponent={<IconPayoutsAndTransfers />}
          isExpanded={isSidebarExpanded}
        />
        <NavLink
          to="/referral-program"
          title={__.referralProgram}
          iconComponent={<IconReferralProgram />}
          isExpanded={isSidebarExpanded}
        />
        <NavLink
          to="/documents"
          title={__.documents}
          iconComponent={<IconDocuments />}
          isExpanded={isSidebarExpanded}
        />
        <NavLink
          to="/profile"
          title={__.profileAndSettings}
          iconComponent={<IconSettings />}
          isExpanded={isSidebarExpanded}
        />
        <NavLink
          to="/cast"
          title={__.cast}
          iconComponent={<IconStreaming />}
          isExpanded={isSidebarExpanded}
          style={{
            borderTop: '1px solid rgba(84,87,105,.3)',
            borderBottom: '1px solid rgba(84,87,105,.3)',
            padding: '10px 0',
          }}
        />

        <NavLink
          to="/auth"
          title={__.signOut}
          iconComponent={<IconSignOut />}
          isExpanded={isSidebarExpanded}
          onClick={signOut}
          exact
        />
      </div>
      <div
        className={s.resizer + (isOnResize ? ' active' : '')}
        onMouseDown={handleResizeStart}
      />
    </div>
  )
}

export default observer(Sidebar)
