const isDev = process.env.NODE_ENV === 'development'
export const backUrl = 'https://cabinet.les.denistiaglin.com:8888'
  // isDev
  //   ? 'http://test.cabinet.les.denistiaglin.com:8888'
  //   : 'https://cabinet.les.denistiaglin.com:8888'

export const backUrlApi = `${backUrl}/api`

export const siteTransferUrl = 'https://my.denistiaglin.com/'

const EXP_GROUP = [
  // Тестовые логины для прода
  // "405opgjkeat6ij8", // @nologicone
  'wind', // @Sidorov_S
  'bulhi', // @bulhik
  '405opgpkrp55ur6', // @BonIlcer
  '405opejl1m0t0cq',
  '405opgjks9y402g', // @RoxetteMiller
  // '405opgikumip1sp' // Служебный аккаунт
]

// На тестовом сервере нет разницы по экспериментальным группам
export const isInExpGroup = login => EXP_GROUP.includes(login)

export const pages = {
  giveMobile: 'GIVE_MOBILE', // if user hasn't confirmed phone
  selection: 'SELECTION', // suggest choos invitor
  entrance: 'ENTRANCE', // main page of entrance
  register: 'REGISTER', // page that ask you register data
  recovery: 'RECOVERY', // input new password
  partner: 'PARTNER', // suggest input login of invitor
  suggest: 'SUGGEST', // show the invitor that user choosepartner
  confirm: 'CONFIRM', // input confirmation code
  forget: 'FORGET', // page for recover password
}

// I just put it down here
export const byteMap = {
  SIGNED_IN: 1, // 00001
  IS_NOT_LOCKED: 2, // 00010
  ACTIVATED: 4, // 00100
  IN_REFERAL_TREE: 8, // 01000
  PHONE_CONFIRMED: 16, // 10000
  ALL_IS_TRUE: 31, // 11111 helper for getting private routes (Activated for new users is not required)
}

export const MODAL_ID = 'MODAL_ID'

export const transferStage = {
  inProgress: "inProgress",
  completed: "completed",
}
