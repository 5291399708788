import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Preloader from 'components/Preloader/Preloader'

const LoadingRoutes = () => {
  return (
    <>
      <Switch>
        <Route path="/" render={() => <Preloader />} />
        <Redirect to="/" />
      </Switch>
    </>
  )
}

export default LoadingRoutes
