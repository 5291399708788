import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import appStore from 'store/app'
import { pages } from './consts'
import history from './history'

import Preloader from 'components/Preloader/Preloader'
import Sidebar from 'components/Sidebar/Sidebar'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'

import GlobalNotification from 'components/GlobalNotification/GlobalNotification'

import Dashboard from 'pages/Dashboard/Dashboard'
import BuyShares from 'pages/BuyShares/BuyShares'
import { AuthPage } from './pages/AuthPage/AuthPage'

import s from './App.module.css'
import NetworkErrorFallback from './components/NetworkErrorFallback/NetworkErrorFallback'
import ErrorBoundary from './components/ErrorBoundary'
import { AskingModals } from './components/Modal/AskingModals'

const InstallmentPayLoader = lazy(() =>
  import('./pages/InstallmentPay/InstallmentPay')
)
const PayoutsAndTransfersLoader = lazy(() =>
  import('./pages/PayoutsAndTransfers/PayoutsAndTransfers')
)
const ReferralProgramLoader = lazy(() =>
  import('./pages/ReferralProgram/ReferralProgram')
)
const CastLoader = lazy(() => import('./pages/Cast/Cast'))
const CryptoPayLoader = lazy(() => import('./pages/CryptoPay/CryptoPay'))
const InvestmentsLoader = lazy(() => import('./pages/Investments/Investments'))
const ProfileLoader = lazy(() => import('./pages/Profile/Profile'))
const DocumentsLoader = lazy(() => import('./pages/Documents/Documents'))

const withSuspense = (ComponentLoader) => (props) => {
  return (
    <ErrorBoundary fallbackComponent={<NetworkErrorFallback />}>
      <Suspense fallback={<Preloader />}>
        <ComponentLoader {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

const InstallmentPay = withSuspense(InstallmentPayLoader)
const PayoutsAndTransfers = withSuspense(PayoutsAndTransfersLoader)
const ReferralProgram = withSuspense(ReferralProgramLoader)
const Cast = withSuspense(CastLoader)
const CryptoPay = withSuspense(CryptoPayLoader)
const Investments = withSuspense(InvestmentsLoader)
const Profile = withSuspense(ProfileLoader)
const Documents = withSuspense(DocumentsLoader)

const {
  setCurrentBreadcrumbs,
  setIsSidebarExpanded,
  signInAsAdmin,
  signOut,
  setWishPage,
  getWishPage,
} = appStore

const PrivateRoutes = ({ langStrings }) => {
  // Скрытие сайдбара
  const hideSidebar = (_) => {
    if (matchMedia('(max-width: 850px)').matches) {
      setIsSidebarExpanded(false)
    }
  }

  if (!langStrings) return <Preloader />

  const __ = (langStrings && langStrings?.App) || {}

  const wishPage = getWishPage()

  if (wishPage.href === window.location.href) {
    setWishPage('/')
  }

  if (wishPage !== '/') {
    history.replace(wishPage)
  }

  return (
    <>
      <GlobalNotification />
      <AskingModals />

      <div className={s.wrapper}>
        <Sidebar />

        <div className={s.main}>
          <Header />
          <Switch>
            {/* <Route path='/' exact render={_ => <Redirect to='/buy-shares' />} /> */}
            <Route
              path="/enter/admin/:key"
              render={(props) => {
                signOut(() => signInAsAdmin(props.match.params.key))
                return <Redirect to="/auth" />
              }}
            />

            {/* Главная */}
            <Route
              path="/"
              exact
              render={(_) => {
                setCurrentBreadcrumbs('Dashboard')
                hideSidebar()
                return <Dashboard />
              }}
            />

            {/* Подтверждение номера телефона */}
            <Route
              path="/confirm"
              render={() => {
                return <AuthPage initialStage={pages.confirm} />
              }}
            />

            {/* Купить акции */}
            <Route
              path="/buy-shares"
              render={(_) => {
                setCurrentBreadcrumbs(__.buyShares)
                hideSidebar()
                return <BuyShares />
              }}
            />

            {/* Оплата криптовалютой */}
            <Route path="/crypto-pay/:id" component={CryptoPay} />

            {/* Рассрочки */}
            <Route path="/investments" exact component={Investments} />

            {/* Оплата рассрочки */}
            <Route
              exact
              path="/installments/pay/:idInstallment"
              component={InstallmentPay}
            />

            {/* Редирект на вкладку 'Выплаты' */}
            <Route
              path="/payouts-and-transfers"
              exact
              render={(_) => <Redirect to="/payouts-and-transfers/payouts" />}
            />

            {/* Рисуем обертку профиля на нужные пути в URL */}
            <Route
              path="/payouts-and-transfers/"
              component={PayoutsAndTransfers}
            />

            {/* Реферальная программа */}
            <Route path="/referral-program" component={ReferralProgram} />

            {/* Документы */}
            <Route path="/documents" component={Documents} />

            {/* Редирект на вкладку "Основная информация" */}
            <Route
              path="/profile"
              exact
              render={(_) => <Redirect to="/profile/main-information" />}
            />

            {/* Рисуем обертку профиля на нужные пути в URL */}
            <Route
              path="(/profile/main-information|/profile/security|/profile/private-settings|/profile/photography|/profile/me)"
              render={(_) => {
                hideSidebar()
                return <Profile />
              }}
            />

            <Route path="/cast" exact component={Cast} />

            {/* Со всех неопределенных роутов перенаправляем на Dashboard */}
            <Route render={(_) => <Redirect to="/" />} />
          </Switch>

          <Footer />
        </div>
      </div>
    </>
  )
}

export default PrivateRoutes
