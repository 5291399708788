import React, { useState, useEffect, useContext, lazy, Suspense, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { default as q } from 'query-string'
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js'

import appStore, { appContext } from 'store/app'
import { pages, byteMap } from '../../consts'

import { AuthHeader } from '../../components/AuthHeader/AuthHeader'
import { AuthFooter } from '../../components/AuthFooter/AuthFooter'
import { Entrance } from '../../components/AuthComponents/Entrance'
import { Register } from '../../components/AuthComponents/Register'

import './AuthPage.scss'

import ErrorBoundary from '../../components/ErrorBoundary'

// Если пользователь зарегистрирован, то не загрузит эти страницы
const GiveMobile = lazy(() => import('../../components/AuthComponents/GiveMobile'))
const Recovery = lazy(() => import('../../components/AuthComponents/Recovery'))
const Forget = lazy(() => import('../../components/AuthComponents/Forget'))
const Confirm = lazy(() => import('../../components/AuthComponents/Confirm'))
const Partner = lazy(() => import('../../components/AuthComponents/Partner'))
const Suggest = lazy(() => import('../../components/AuthComponents/Suggest'))

const AuthPage = observer(() => {
  // Мы хотим рисовать Confirm 2 раза
  // Поэтому используем флаг, чтобы понять, какая цель отрисовки сейчас
  const [recovery, setRecovery] = useState(false)
  const [redirected, setRedirected] = useState(false)

  const _ = useContext(appContext)
  const userData = _.userData || {}
  const langStrings = _.langStrings || {}

  const formRef = useRef(null)

  useEffect(() => {
    if (!(_.userStateOfByte & byteMap.SIGNED_IN)) {
      const redirectParam = q.parseUrl(window.location.search).query.redirect
      if (redirected) {
        return
      }

      if (redirectParam === 'signup') {
        _.setAuthStage(pages.register)
        setRedirected(true)
      }

      return
    }

    if (!(_.userStateOfByte & (byteMap.ACTIVATED | byteMap.PHONE_CONFIRMED))) {
      _.setAuthStage(pages.confirm)
      return
    }

    if (!(_.userStateOfByte & byteMap.IN_REFERAL_TREE)) {
      if (_.suggestedInvitor !== null) {
        _.setAuthStage(pages.suggest)
      } else {
        _.setAuthStage(pages.partner)
      }
    }
  }, [_, _.userStateOfByte, redirected])

  const linkTo = (page) => (e) => {
    if (e !== undefined) {
      e.preventDefault()
    }
    _.setAuthStage(page)
  }

  const signIn = ({ phone, email, login, password }) => {
    if (phone && !isValidNumber(phone)) {
      appStore.addNotification('error', 'Invalid Phone number')
      return
    }

    // errorLoginPasswordWrong - late will be lang support
    appStore.signIn({
      phone,
      email,
      login,
      password,
      langString: {
        errorLoginPasswordWrong: 'Incorrect Phone or password',
      },
    })

    // setStage(pages.confirm);
  }

  const signUp = ({ phone, password }) => {
    const parsedPhone = parsePhoneNumber(phone)

    if (!parsedPhone.isValid()) {
      appStore.addNotification('error', 'Invalid Phone number')
      return
    }
    appStore.signUp({
      phone: phone.replace('+' + parsedPhone.countryCallingCode, ''),
      password,
      code: parsedPhone.country,
      fullPhone: phone,
    })
  }

  const askCode = () => {
    if (!recovery) {
      _.askForNewActivationCode()
    } else {
      _.recoverGetCode({})
    }
  }

  const sendCode = ({ code }) => {
    if (!recovery) {
      _.sendActivationCode(code)
    } else {
      _.recoverSendCode({ key: code })
    }
  }

  const stages = new Map()
    .set(pages.suggest, <Suggest linkToPartner={linkTo(pages.partner, Partner)} />)
    .set(
      pages.entrance,
      <Entrance linkToForget={linkTo(pages.forget)} linkToRegister={linkTo(pages.register)} signIn={signIn} />
    )
    .set(
      pages.register,
      <Register linkToAuth={linkTo(pages.entrance)} linkToConfirm={linkTo(pages.confirm)} signUp={signUp} />
    )
    .set(pages.confirm, <Confirm linkToEntrance={linkTo(pages.entrance)} sendCode={sendCode} askCode={askCode} />)
    .set(
      pages.partner,
      <Partner signOut={_.signOut} linkToEntrance={linkTo(pages.entrance)} linkToSuggest={linkTo(pages.suggest)} />
    )
    .set(
      pages.forget,
      <Forget
        setRecovery={() => {
          setRecovery(true)
        }}
        linkToEntrance={linkTo(pages.entrance)}
      />
    )
    .set(
      pages.giveMobile,
      <GiveMobile phone={userData.phone} linkToConfirm={linkTo(pages.confirm)} langString={langStrings?.GiveMobile} />
    )
    .set(pages.recovery, <Recovery />)

  return (
    <div className="auth-wrapper">
      <AuthHeader />
      <main className="auth-main auth-container">
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={_.authStage}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false)
            }}
            classNames="fade"
          >
            <div id="main__form" ref={formRef}>
              <ErrorBoundary
                FallbackComponent={stages.get(_.prevAuthStage)}
                errorNotificate={() => _.networkErrorHandler()}
              >
                <Suspense fallback={<></>}>{stages.get(_.authStage)}</Suspense>
              </ErrorBoundary>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </main>
      <AuthFooter />
    </div>
  )
})

export { AuthPage }
