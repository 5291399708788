import React, { useEffect } from 'react'
import intlTelInput from 'intl-tel-input'

import { cn } from '@bem-react/classname'

import './checkbox.scss'

export const InputPassword = ({
  value,
  onChange,
  placeholder = 'password',
  name = 'password',
  required = true,
  props = {},
}) => (
  <>
    <div
      className="auth-main__input auth-main__input-password"
      data-lable="Пароль"
    >
      <div className="auth-main__input-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.588"
          height="11.587"
          viewBox="0 0 11.588 11.587"
        >
          <path
            d="M193.022,189.374a3.645,3.645,0,0,1,3.648,3.648,4.494,4.494,0,0,1-.215,1.287l4.506,4.506v2.146h-2.146v-1.287h-1.287v-1.287H196.24l-1.931-1.931a4.045,4.045,0,0,1-1.287.215,3.648,3.648,0,0,1,0-7.3Zm-.555,4.38a1.287,1.287,0,1,0-1.287-1.287A1.264,1.264,0,0,0,192.467,193.754Z"
            transform="translate(-189.374 -189.374)"
          />
        </svg>
      </div>
      <input
        type="password"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        {...props}
      />
    </div>
  </>
)

export const InputPhone = ({
  value,
  onChange,
  placeholder = 'Phone number',
  name = 'phone',
  required = true,
  props = {},
}) => {
  useEffect(() => {
    const input = props.ref.current

    intlTelInput(input, {
      preferredCountries: ['ru'],
      formatOnDisplay: true,
    })
  }, [props.ref])

  return (
    <>
      <div
        className="auth-main__input auth-main__input-phone"
        data-lable="Номер телефона"
      >
        <div className="auth-main__input-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.348"
            height="14.229"
            viewBox="0 0 10.348 14.229"
          >
            <path
              d="M81.674,11.642a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,81.674,11.642ZM77.794,0a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,77.794,0Zm0,3.881a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,77.794,3.881Zm0,3.881a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,77.794,7.761Zm7.761-5.174a1.294,1.294,0,1,0-1.294-1.294A1.3,1.3,0,0,0,85.555,2.587ZM81.674,7.761a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,81.674,7.761Zm3.881,0a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,85.555,7.761Zm0-3.881a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,85.555,3.881Zm-3.881,0a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,81.674,3.881Zm0-3.881a1.294,1.294,0,1,0,1.294,1.294A1.3,1.3,0,0,0,81.674,0Z"
              transform="translate(-76.5)"
            />
          </svg>
        </div>

        <input
          type="tel"
          name={name}
          pattern="^\+.+"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          {...props}
        />
      </div>
    </>
  )
}

export const InputEmail = ({
  value,
  onChange,
  placeholder = 'Email',
  name = 'email',
  required = true,
  props = {},
}) => (
  <>
    <div className="auth-main__input auth-main__input-email">
      <div className="auth-main__input-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10.254"
          height="9.741"
          viewBox="0 0 10.254 9.741"
        >
          <path
            d="M10.254,16.339a1.142,1.142,0,0,0-.461-.872L5.127,12.75.461,15.467A1.142,1.142,0,0,0,0,16.339v5.127a1.028,1.028,0,0,0,1.025,1.025h8.2a1.028,1.028,0,0,0,1.025-1.025ZM5.127,18.9.872,16.236l4.255-2.461,4.255,2.461Z"
            transform="translate(0 -12.75)"
            fill="#33334a"
          />
        </svg>
      </div>

      <input
        type="email"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        {...props}
      />
    </div>
  </>
)

export const InputLogin = ({
  value,
  onChange,
  placeholder = 'Login',
  name = 'login',
  required = true,
  props = {},
}) => (
  <>
    <div className="auth-main__input auth-main__input-login">
      <div className="auth-main__input-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9.54"
          height="12.475"
          viewBox="0 0 9.54 12.475"
        >
          <g transform="translate(-1520.715 -42.244)">
            <path
              d="M.008,10.989C0,10.9,0,10.819,0,10.733A4.543,4.543,0,0,1,4.771,6.457a4.543,4.543,0,0,1,4.77,4.275c0,.086,0,.172-.008.257a8.375,8.375,0,0,1-9.524,0Zm2.115-8.27A2.684,2.684,0,0,1,4.771,0,2.683,2.683,0,0,1,7.417,2.719a2.684,2.684,0,0,1-2.646,2.72A2.684,2.684,0,0,1,2.124,2.719Z"
              transform="translate(1520.715 42.244)"
              fill="#33334a"
            />
          </g>
        </svg>
      </div>

      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        {...props}
      />
    </div>
  </>
)

const cnCheckbox = cn('Checkbox')

export const InputCheckbox = ({ checked, onChange, title }) => (
  <label className={cnCheckbox()} htmlFor="combined">
    <input
      type="checkbox"
      id="combined"
      checked={checked}
      onChange={onChange}
    />
    <span className={cnCheckbox('FakeCheckbox')} />
    <span className={cnCheckbox('Text')}>{title}</span>
  </label>
)
