import React, { useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import appStore from 'store/app'
import { backReq } from 'helpers'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import YesNoModal from 'components/Modal/YesNo'
import s from './IncomeTransfers.module.css'

const {
  setAppLoading,
  addNotification,
  getInternalTransfers,
  getTotalWidgetData,
} = appStore

const IncomeTransfers = (_) => {
  const [codeProtection, setCodeProtection] = useState('')
  const changeCodeProtection = (e) => setCodeProtection(e.target.value)

  const acceptTransfer = async (_) => {
    setAppLoading(true)

    const res = await backReq('/private/transfer', {
      method: 'accept',
      submethod: 'internal',
      codeProtection,
    })

    setAppLoading(false)

    if (res.status === 'success') {
      switch (res.status) {
      case 'success':
        addNotification('success', 'You received the money.')
        getInternalTransfers('notification')
        getInternalTransfers('all')
        getTotalWidgetData()
        break

      case 'notExist':
        addNotification(
          'error',
          'Protection code is wrong. Please ask for code from person who want to receive.'
        )
        break

      default:
        break
      }
    }
  }

  const yesNoModalClose = (_) => setYesNoModalVisible(false)

  const [idTransferInternal, setIdTransferInternal] = useState(null)

  const cancelTransfer = (e) => {
    setIdTransferInternal(e.target.dataset.id)
    setYesNoModalText('Are you sure to cancel income transfer?')
    setYesNoModalVisible(true)
  }

  const cancelTransferRequest = async (_) => {
    setAppLoading(true)
    const res = await backReq('/private/transfer', {
      method: 'cancel',
      submethod: 'internal',
      idTransferInternal,
    })
    setAppLoading(false)

    switch (res.status) {
      case 'success':
        addNotification('success', 'Transfer canceled')
        getInternalTransfers('notification')
        getInternalTransfers('all')
        break

      case 'notExist':
        addNotification('error', 'Transfer not exists')
        break

      default:
        break
    }

    setYesNoModalVisible(false)
  }

  const [yesNoModalVisible, setYesNoModalVisible] = useState(false)
  const [yesNoModalText, setYesNoModalText] = useState(false)

  if (!appStore.transfersNotification.length) return null

  const transferData = toJS(appStore.transfersNotification.reverse()[0])

  return (
    <div className={s.transfersNotification}>
      <h2 className="mainTitle">You have income transfers</h2>

      <p>
        Investor with login <strong>{transferData.loginOfTransfer}</strong> sent
        you <strong>{transferData.amount} RUB</strong>.<br />
        He should contact with you by phone, social network, etc and give
        protection code.
        <br />
        You need to enter this code into the field below. Then transfer will be
        accepted and money add to your balance.
      </p>

      <div className={s.transferWrapper}>
        {/* <span className={s.transferId}>Transfer #234</span> */}
        <Input
          placeholder="Protection code"
          value={codeProtection}
          onChange={changeCodeProtection}
        />
        <Button type="info" onClick={acceptTransfer} disabled={!codeProtection}>
          Accept
        </Button>
        <Button
          type="danger"
          onClick={cancelTransfer}
          data-id={transferData._id}
        >
          Cancel
        </Button>
      </div>

      {yesNoModalVisible && (
        <YesNoModal
          text={yesNoModalText}
          yesHandler={cancelTransferRequest}
          noHandler={yesNoModalClose}
        />
      )}
    </div>
  )
}

export default observer(IncomeTransfers)
