import { useEffect, useMemo, useState } from 'react'
import { isValidNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment'

import 'moment/locale/ru'
import 'moment/locale/fr'
import 'moment/locale/de'

import { backUrlApi } from 'consts'
import appStore from 'store/app'
import { CURRENCIES } from './store/app'

// Универсальный запрос к бэку
export const backReq = (endpoint = '', body = {}) => {
  let options = {
    method: 'POST',
    credential: 'include',
  }

  if (appStore.token) {
    body.jwtToken = appStore.token
  }

  if (body?.constructor && body?.constructor.name !== 'FormData') {
    options.headers = { 'Content-Type': 'application/json' }
  }

  if (body) {
    if (body && body.constructor && body.constructor.name === 'FormData') {
      options.body = body
    } else {
      options.body = JSON.stringify(body)
    }
  }

  const promise = fetch(backUrlApi + endpoint, options)
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 'notAuthenticate') appStore.destroyLoggedInState()
      return res
    })
    .catch((e) => {
      appStore.addNotification(e.message)
    })

  return promise
}

const backCache = {}
/**
 *
 * @param {string} endpoint Конечный роут без api/
 * @param {object|null} body тело запроса
 * @param {boolean} cacheMode флаг необходимости кеширования (по умолчанию true)
 * @returns {Promise} Промис с результатом выполнения запроса
 */
export const backReqWithCache = async (
  endpoint = '',
  body = {},
  cacheMode = true
) => {
  // Ключ, обеспечивающий уникальность запроса
  const key =
    endpoint +
    ':' +
    Object.entries(body).flatMap((c) => c[0] + c[1].toString()) +
    ':' +
    appStore.token

  if (backCache[key] && cacheMode) {
    return backCache[key]
  }

  backCache[key] = await backReq(endpoint, body)

  return backCache[key]
}

// Форматирование денежных сумм
export const formatNumbers = ({
  amount = 0,
  integer,
  isCurrency = false,
  isInput = false,
  fixed,
  filler = '\xa0',
}) => {

  let ratedAmount = amount

  if(isCurrency){
    ratedAmount = appStore.cur === CURRENCIES.USD ? amount / 75 : amount
  }

  if (fixed) {
    const delimeter = Math.pow(10, fixed)
    ratedAmount = ((ratedAmount * delimeter) | 0) / delimeter
  }

  const isFractional = ratedAmount.toString().split('.').length === 2
  let amountLeft, amountRight

  const joinWithCurrency = (str) => {
    if (isCurrency && !isInput) {
      if (appStore.cur === 'RUB') {
        return str + filler + '₽'
      } else if (appStore.cur === 'USD') {
        return '$' + filler + str
      } else if (appStore.cur) {
        return str + appStore.cur
      } 
    } else{
      return str
    }
  }

  if (isFractional) {
    amountLeft = ratedAmount.toString().split('.')[0]
    amountRight = ratedAmount.toString().split('.')[1]
  } else {
    amountLeft = ratedAmount
  }

  const amountArr = (+amountLeft).toString().split('').reverse()
  let formattedArr = []

  for (let i = 0; i < amountArr.length; i++) {
    if (i % 3 === 0 && i > 0) {
      formattedArr.push(filler, amountArr[i]) // With thin space
    } else {
      formattedArr.push(amountArr[i])
    }
  }

  if (isFractional) {
    formattedArr.unshift('.')
    formattedArr.unshift(amountRight)
  }

  if (integer) {
    return joinWithCurrency(formattedArr.reverse().join('').split('.')[0])
  }

  return joinWithCurrency(formattedArr.reverse().join(''))
}

// Форматирование локализованных дат
export const formatDate = (date, format) => {
  return moment(date).locale(appStore.lang).format(format)
}

// Отслеживание клика вовне
export const useOutsideClick = (ref, callback) => {
  const handleClick = ({ target }) => {
    // ref это объект
    if (typeof ref === 'object') {
      if (ref.current && !ref.current.contains(target)) callback()
    }

    // ref это строка с указанным селектором.
    // Используется, когда нужно изменять состояние одного компонента, для которого
    // clickOutside отслеживается в разных местах
    else {
      const notContainsArr = []
      document
        .querySelectorAll(ref)
        .forEach((el) => notContainsArr.push(!el.contains(target)))
      if (notContainsArr.every((boolVal) => boolVal)) callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

// Значения таймера и его окончания
export const useTimer = (finish, callback = () => {}) => {
  // Это хначение не нужно вычислять каждую секунду
  const expireMoment = useMemo(() => Date.parse(finish), [finish])

  // Хелпер для зачений таймера
  const padding = (value) => value.toString().padStart(2, '0')

  const [time, setTime] = useState(getValue())

  if (typeof Date.parse(finish) !== 'number') {
    throw new Error('В таймер передано некорректное значение')
  }

  function getValue() {
    const time = ((expireMoment - Date.now()) / 1000) | 0

    if (time <= 0) {
      return '00:00'
    } else {
      const seconds = time % 60
      const minutes = (time - seconds) / 60

      return padding(minutes) + ':' + padding(seconds)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const value = getValue()
      setTime(value)

      if (value === '00:00') {
        clearInterval(interval)
        callback()
      }
    }, 1000)

    return () => clearInterval(interval)
  })

  return time
}

// Рисование SVG-дуги
export const describeArc = (x, y, radius, startAngle, endAngle) => {
  // Перевод полярных координат в картезианские
  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    }
  }

  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
    startAngle === 0 && endAngle === 359 ? 'Z' : '',
  ].join(' ')

  return d
}

// Перевод одной шкалы в другую
export const scale = (num, inMin, inMax, outMin, outMax) => {
  return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

// запросы на localStorage
const defaultItem = 'invite'
export const storage = {
  getStorage: (item = defaultItem) => {
    try {
      return JSON.parse(localStorage.getItem(item))
    } catch {
      return null
    }
  },

  setStorage(item = 'invite', value) {
    return localStorage.setItem(item, JSON.stringify(value))
  },

  addInvitor(value) {
    if (!value) {
      return
    }

    let currentStorage = this.getStorage()

    if (!Array.isArray(currentStorage)) {
      return this.setStorage('invite', [value])
    }

    if (currentStorage.includes(value)) {
      let newStorage = [...currentStorage].filter((c) => c !== value)
      newStorage.push(value)
      this.setStorage('invite', newStorage)
      return
    }

    this.setStorage(defaultItem, [...currentStorage, value])
  },

  getLastInvitor() {
    let currentStorage = this.getStorage()
    if (Array.isArray(currentStorage)) return currentStorage.pop()
  },

  removeLastInvitor() {
    let currentStorage = this.getStorage()

    if (!currentStorage || !currentStorage.length) {
      return null
    }

    currentStorage.pop()
    this.setStorage(defaultItem, currentStorage)
  },
}

// нормализация телефона
export const phoneToDefault = (phone) => {
  phone = phone.replace(/[\s-()_]/gi, '')
  phone = parsePhoneNumberFromString(phone)
  if (phone === undefined) {
    throw new Error('Phone is invalid')
  }
  return phone
}

export const groupByDate = (items = []) => {
  if (items.length === 0) return
  const result = []

  const itemsObj = items
    .filter((c) => !!(c.currentCountBalance - c.newCountBalance))
    .filter((c) => c.type !== 'reservedPay')
    .reduce((obj, initialPayout) => {
      const datetime = moment(initialPayout.createDate).locale(appStore.lang)

      const date = datetime.utc().format('LL')
      const time = datetime.utc().format('HH:mm [(GMT)]')

      const diff =
        initialPayout.newCountBalance - initialPayout.currentCountBalance
      const amount =
        diff > 0
          ? '+\u00A0' +
            formatNumbers({
              amount: diff,
              isCurrency:true,
              filler: '\u00A0',
              fixed: 2,
            })
          : formatNumbers({
            amount: diff,
            isCurrency:true,
            filler: '\u00A0',
            fixed: 2,
          })


      const payouts = {
        ...initialPayout,
        timestamp: time,
        amount,
        status:
          appStore.langStrings?.HistoryStatus[initialPayout.status] ||
          appStore.langStrings?.History[initialPayout.type],
        type: appStore.langStrings?.HistoryTypes[initialPayout.type],
        systemType: initialPayout.type,
        systemStatus: initialPayout.status,
      }

      if (obj[date] !== undefined) {
        return {
          ...obj,
          [date]: [...obj[date], payouts],
        }
      } else {
        return {
          ...obj,
          [date]: [payouts],
        }
      }
    }, {})

  for (let datePayout in itemsObj) {
    result.push({
      date: datePayout,
      payout: itemsObj[datePayout],
    })
  }

  return result
}

export const getCookie = (name) => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

/**
 *
 * @param {function} func Обработчик для прореживания
 * @param {number} ms Время задержки
 * @returns Функция, которая вызывается на чаще чем раз в определенное время
 */
export const throttling = (func, ms) => {
  let isThrottled = false,
    savedArgs,
    savedThis

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments
      savedThis = this
      return
    }

    func.apply(this, arguments)

    isThrottled = true

    setTimeout(function () {
      isThrottled = false
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = savedThis = null
      }
    }, ms)
  }

  return wrapper
}

/**
 * Функция валидатор номера (номер существует?)
 * @todo Языковая поддержка
 * @param {string} phoneNumber - Номер телефона из инпута
 * @returns {{isValid: boolean, errorMsg: string}}
 */
export const validatorPhoneExist = (phoneNumber) => ({
  isValid: isValidNumber(phoneNumber),
  errorMsg: 'Пожалуйста, проверьте правильность написания телефона',
})

/**
 * Функция валидатор номера (международный формат)
 * @todo Языковая поддержка
 * @param {string} phoneNumber - Номер телефона из инпута
 * @returns {{isValid: boolean, errorMsg: string}}
 */
export const validatorPhoneInternational = (phoneNumber) => ({
  isValid: phoneNumber[0] === '+',
  errorMsg: 'Пожалуйста, проверьте правильность написания телефона',
})

/**
 * Функция валидатор строки (точная длина)
 * @todo Языковая поддержка
 * @param {number} len - Допустимое количество символов
 * @returns {(value: string) => {isValid: boolean, errorMsg: string}}
 */
export const getValidatorStringLength = (len) => (value) => ({
  isValid: value.length === len,
  errorMsg:
    'Пожалуйста, проверьте значение - допустимое количество символов: ' + len,
})

/**
 * Функция валидатор почты (RFC 5322)
 * @todo Языковая поддержка
 * @param {string} email - Адрес электронной почты из инпута
 * @returns {{isValid: boolean, errorMsg: string}}
 */ export const validatorEmail = (email) => {
  // eslint-disable-next-line no-control-regex
  const re =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  return {
    isValid: re.test(email),
    errorMsg: 'Пожалуйста, проверьте правильность написания электронной почты',
  }
}

/**
 * НЕ РАБОТАЕТ!!!
 * Сравнивает со значением по рефу
 * @todo Языковая поддержка
 * @param {React.RefObject} fieldRef - ссылка на поле для сравнения
 * @returns {(value: string) => {isValid: boolean, errorMsg: string}}
 */
export const getValidatorEqualToRef = (fieldRef) => (value) => ({
  isValid: value === fieldRef.current?.value,
  errorMsg: fieldRef.current?.value + ' = ' + value,
})
