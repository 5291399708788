import { backReq } from 'helpers'
import { byteMap, isInExpGroup, pages, transferStage } from '../consts'
import { createContext } from 'react'
import { storage, groupByDate, getCookie, backReqWithCache } from '../helpers'
import history from '../history'
import moment from 'moment'
import { observable, action, toJS, configure, runInAction, computed, get } from 'mobx'

configure({ enforceActions: 'always' })

export const CURRENCIES = {
  RUB: 'RUB',
  USD: 'USD',
}
//константы валют

const timeout = 60
//Хардкод тайминга повторного запроса активации.

const hardXchangeCourse = 75
//Хардкод курса доллара.

class AppModel {
  // ==========================
  // Функции общего применения
  // ==========================

  /** Запрос с изменением статтуса приложения.
   * @param {Function} callback запрос.
   * Функция изменяющая статус приложения при выполнении асинхронного запроса.
   **/
  @action request = async (callback) => {
    this.setAppLoading(true)
    await callback()
    this.setAppLoading(false)
  }
  //==============================================================================

  /** Обработчик ошибок сети
   * Используется при потере соединения, вызывает всплывающее окно с сообщением.
   **/
  @action networkErrorHandler = () => {
    appStore.addNotification(
      'error',
      `${this.langStrings?.Errors.errorNetwork[0]} ${this.langStrings?.Errors.errorNetwork[1]}`,
    )
  }
  //==============================================================================

  //Задаем курс доллора из константы.
  @observable xchangeCourse = hardXchangeCourse
  //Указываем что курс задан вручную.
  xchangeCourseSetted = false

  /** Запрос на установку курса валюты
   * Если курс не задан вручную, то отправляем запрос на бек и узнаем его
   * Если получаем ответ, то задаем курс из ответа
   * runInAction - является надстройкой для action
   * и позволяет не выносить всю логику обратного вызова в отдельный action-метод
   **/
  @action setXchangeCourse = async () => {
    if (this.xchangeCourseSetted) {
      return this.xchangeCourse
    }

    backReq('/private/info', {
      method: 'receive',
      submethod: 'exchangeRate',
    })
      .then((res) => {
        if (res.status === 'success') {
          runInAction(
            'setting course as ' + res.data.RUB,
            () => (this.xchangeCourse = res.data.RUB),
          )
          this.xchangeCourseSetted = true
          return res.data.RUB
        }
      })
      .catch((error) => {
        runInAction('setting hard Course on error', () => (this.xchangeCourse = hardXchangeCourse))
      })
      .finally(() => {
        if (!this.xchangeCourse) {
          runInAction(
            'setting hard course finally ',
            () => (this.xchangeCourse = hardXchangeCourse),
          )
        }
      })
  }
  //==============================================================================

  // ==========================
  // Auth page(stages) state
  // ==========================

  //Стадия авторизации
  @observable authStage = pages.entrance
  //Предыдущая стадия авторизации
  @observable prevAuthStage = pages.entrance
  //Задаем стадию авторизации
  @action setAuthStage = (page) => {
    this.prevAuthStage = this.authStage
    this.authStage = page
  }
  //==============================================================================

  /** Достаем роут из localStorage
   * @return {string} строка с адресом роута
   **/
  @action getWishPage = () => {
    return storage.getStorage('wish-page') || '/'
  }
  //==============================================================================

  /** Записываем роут в localStorage
   * @param {string} route строка с адресом роута
   **/
  @action setWishPage = (route) => {
    storage.setStorage('wish-page', route)
  }
  //==============================================================================

  // ==========================
  // Флаг, указывающий что юзер залогинился как админимтратор
  // ==========================
  @observable sighnedAsAdmin = false

  //Устанавливаем вышеупомянутый флаг
  @action setSighnedAsAdmin = () => {
    this.sighnedAsAdmin = true
  }
  //==============================================================================

  /** Обработка ошибки прав Админа
   * Используется при недоступном действии в режиме админа
   **/
  @action notificateErrorAdmin = () => {
    this.addNotification('error', 'Эта операция недоступна в режиме администратора')
  }
  //==============================================================================

/** Вычисляемое свойство
   * Если залогинились как админ, то получаем доступ к доп. списку страниц
   **/
  @computed get adminRestrictedButton() {
    return !this.sighnedAsAdmin
      ? []
      : [
          'buy-shares',
          'transfers',
          'payouts',
          'setting/main',
          'setting/security',
          'setting/private',
          'setting/photo',
        ]
  }

  @computed get siteTransfer() {
    return true
  }

  @computed get siteTransferStage() {
    return transferStage.completed
  }

  // ==========================
  // Suggested Invitor state
  // ==========================
  @observable suggestedInvitor = null

  @action loginToNewSite = async () => {
    return backReq('/private/referral/new-cabinet-login')
  }

  @action setSuggestedInvitor = async (invitor, silentMode = false) => {
    this.setAppLoading(true)

    backReq('/private/info', {
      method: 'receive',
      submethod: 'referral',
      login: invitor.toLowerCase(),
    })
      .then((res) => {
        if (res.status === 'success') {
          if (!res.data) {
            throw new Error(this.langStrings?.Errors.errorBlockUser)
          }
          runInAction('setting data about suggested invitor', () => {
            this.suggestedInvitor = res.data
          })
          this.setAuthStage(pages.suggest)
        } else {
          throw new Error(res.status)
        }
      })
      .catch((e) => {
        if (e.message === 'notExist') {
          if (silentMode) {
            storage.removeLastInvitor()

            const nextCandidate = storage.getLastInvitor()
            if (nextCandidate) {
              this.setSuggestedInvitor(nextCandidate, true)
            }
            return
          }
          this.addNotification('error', this.langStrings?.Errors.errorReferral)
          this.setAuthStage(pages.partner)
          return
        }
        if (e.messaage === 'notActivated') {
          this.addNotification('error', this.langStrings?.Errors.unknounError)
          this.signOut()
        }
        runInAction('Error while setting invitor', () => this.addNotification('error', e.message))
      })

    this.setAppLoading(false)
  }

  @action setInvitor = async () => {
    const { status } = await backReq('/private/referral', {
      method: 'add',
      submethod: 'userOfInvited',
      login: this.suggestedInvitor.login,
    })

    if (status === 'success') {
      runInAction('Setting invitor', () => {
        this.addNotification('success', this.langStrings?.Success.invitor)
        this.userData.inTree = true
      })
    }
  }

  // ==========================
  // Manupulation with phone number
  // ==========================
  @action changePhoneNumber = async (phoneNumber) => {
    const { status } = await backReq('/private/user', {
      method: 'update',
      submethod: 'phone',
      phone: phoneNumber,
    })

    switch (status) {
      case 'success':
        // this.addNotification("success", this.langStrings?.Success.activation);
        return true
      case 'notUpdate':
        this.addNotification('error', this.langStrings?.Errors.notUpdate)
        break
      case 'existPhone':
        this.addNotification('error', this.langStrings?.Errors.errorAlreadyExist)
        break
      case 'invalidPhone':
        this.addNotification('error', this.langStrings?.Errors.errorInvalidPhone)
        break
      default:
        this.addNotification('error', this.langStrings?.Errors.unknowError)
        break
    }
    return false
  }

  // ==========================
  // Manupulation with activation code
  // ==========================
  @action askForNewActivationCode = async () => {
    if (this.activationResendTimeout) {
      this.addNotification('error', this.langStrings?.Errors.errorConfirmOften)
      return
    }
    this.setActivationResendTimeout(timeout)

    const { status, data } = await backReq('/private/verify', {
      method: 'confirm',
      submethod: 'set',
      type: 'phone',
    })

    if (status === 'success') {
      runInAction('i realy dont understand what is it', () => {
        this.activationTimeoutError = 'exist'
      })
      this.addNotification('success', this.langStrings?.Success.recoverySent)
    } else {
      runInAction('error while asking for new code', () => {
        this.activationTimeoutError = status
        this.activationResendTimeout = null
        if (status === 'blocked') {
          const is24 = data.includes('24')
          !is24 && this.setActivationResendTimeout(timeout)
          const msg = is24
            ? this.langStrings?.Errors.errorBlock24h
            : this.langStrings?.Errors.errorBlock1m
          this.addNotification('error', msg)
          return
        }
        this.addNotification('error', this.langStrings?.Errors.unknowError)
        this.setAuthStage(pages.entrance)
        this.signOut()
        return
        // this.addNotification('error', 'Confirm code is not accepted. Please, try again!')
      })
    }
  }

  @action sendActivationCode = async (code) => {
    this.setActivationResendTimeout(null)

    const { status } = await backReq('/private/verify', {
      method: 'confirm',
      submethod: 'del',
      type: 'phone',
      key: +code,
    })

    switch (status) {
      case 'success':
        this.addNotification('success', this.langStrings?.Success.activation)
        runInAction('code success confirmed', () => {
          this.userData.isPhoneConfirmed = true
        })
        return true
      case 'invalidKey':
        this.addNotification('error', this.langStrings?.Errors.errorInvalidKey)
        break
      case 'notUpdate':
        this.addNotification('error', this.langStrings?.Errors.unknowError)
        break
      default:
        break
    }

    return false
  }

  // ==========================
  // Manupulation with recover code
  // ==========================
  @observable recoveryPhone = ''
  @observable recoveryEmail = ''
  @observable isEmail = false
  @action setIsEmail = (isEmail) => {
    this.isEmail = isEmail
  }

  @action recoverGetCode = async ({
    phone = this.recoveryPhone,
    email = this.recoveryEmail,
    isEmail = this.isEmail,
  }) => {
    if (!phone) {
      runInAction('setting email as recovery weapon', () => {
        this.isEmail = true
      })
    }

    if (this.activationResendTimeout) {
      this.addNotification('error', this.langStrings?.Errors.errorConfirmOften)
      this.setAppLoading(false)
      return
    }

    this.setActivationResendTimeout(timeout)

    const payload = {
      method: 'password',
      type: isEmail ? 'email' : 'phone',
      submethod: 'createCode',
      phone,
      email,
    }

    const { status, data } = await backReq('/public/recovery', payload)

    // const status = "success";

    if (status === 'success') {
      runInAction('correct send recovery code', () => {
        this.addNotification('success', this.langStrings?.Success.recoverySent)
        this.recoveryPhone = phone
        this.recoveryEmail = email
        this.setAuthStage(pages.confirm)
        this.setActivationResendTimeout(timeout)
      })
    } else if (status === 'alreadyAuthenticate') {
      backReq('/private/signout')
        .then(() => this.setActivationResendTimeout(0))
        .then(() => this.recoverGetCode(phone))
      return
    } else {
      let errorText = ''

      if (status === 'blocked') {
        const is24 = data.includes('24')
        !is24 && this.setActivationResendTimeout(timeout)
        const msg = is24
          ? this.langStrings?.Errors.errorBlock24h
          : this.langStrings?.Errors.errorBlock1m
        this.addNotification('error', msg)
        return
      }

      switch (status) {
        case 'blocked':
          errorText = this.langStrings?.Errors.errorBlockUser
          break
        default:
          errorText = this.langStrings?.Errors.unknowError
      }

      runInAction('add notification - error in recoveryGetCode', () => {
        this.addNotification('error', errorText)
      })
    }
  }

  @action recoverSendCode = async ({ key }) => {
    const { status } = await backReq('/public/recovery', {
      method: 'password',
      type: this.isEmail ? 'email' : 'phone',
      submethod: 'checkCode',
      key,
      phone: this.recoveryPhone,
      email: this.recoveryEmail,
    })

    if (status === 'success') {
      if (this.isEmail) {
        this.addNotification('success', this.langStrings?.Success.activationEmail)
      } else {
        this.addNotification('success', this.langStrings?.Success.activation)
      }

      this.setAuthStage(pages.recovery)
      this.setActivationResendTimeout(0)
      return
    }

    if (status === 'invalidKey') {
      runInAction('error invalidKey by send recovery code', () => {
        this.addNotification('error', this.langStrings?.Errors.errorWrongCode)
      })
      return
    } else {
      runInAction('unknown error while send recovery code', () => {
        this.addNotification('error', this.langStrings?.Errors.unknowError)
      })
    }
  }

  @action recoverChangePassword = ({ password }) => {
    this.setAppLoading(true)

    backReq('/public/recovery', {
      method: 'password',
      type: this.isEmail ? 'email' : 'phone',
      submethod: 'changePassword',
      phone: this.recoveryPhone,
      email: this.recoveryEmail,
      password,
    })
      .then((res) => {
        if (res.status === 'success') {
          runInAction('correct change password', () => {
            // this.addNotification("success", __.successRecover)
            this.addNotification('success', this.langStrings?.Success.recoveryChange)
            this.signIn({
              phone: this.recoveryPhone,
              email: this.recoveryEmail,
              password,
            })
            runInAction('clear recovery stats', () => {
              this.recoveryPhone = ''
              this.recoveryEmail = ''
            })
            // history.push("/auth");
          })

          return res.data
        } else {
          throw new Error(res.status)
        }
      })
      .catch((e) => {
        this.addNotification('error', this.langStrings?.Errors.unknowError)
      })
      .finally(() => this.setAppLoading(false))
  }

  // ==========================
  // Auth state
  // ==========================
  @action signUp = ({ phone, password, code, fullPhone }) => {
    this.request(() => {
      backReq('/public/signup', {
        phone,
        password,
        code,
      }).then((res) => {
        if (res.status === 'success') {
          runInAction('notificate success signUp', () =>
            this.addNotification('success', this.langStrings?.Success.registration),
          )

          this.signIn({ phone: fullPhone, password })
        } else {
          let text

          switch (res.status) {
            case 'existLogin':
              text = this.langStrings?.Errors.errorAlreadyExist
              this.signIn({ phone: fullPhone, password })
              break
            case 'invalidLogin':
              text = 'Login should starts from letter and may contain only letters and digits'
              break
            case 'existEmail':
              text = 'Email already exists'
              break
            case 'invalidEmail':
              text = 'Email should not be empty and should be in form of "example@domain.com"'
              break
            case 'invalidPassword':
              text = this.langStrings?.Errors.errorPasswordShort
              break
            case 'invalidName':
              text = 'Name should not be empty'
              break
            case 'invalidLastname':
              text = 'Lastname should not be empty'
              break
            case 'invalidPhone':
              text = 'Phone should not be empty'
              break
            case 'existPhone':
              text = this.langStrings?.Errors.errorAlreadyExist
              this.signIn({ phone: fullPhone, password })
              break
            case 'invalidLoginOfInvited':
              text = 'Invite login should not be empty'
              break
            case 'notExistLoginOfInvited':
              text = 'Entered invite login not exists'
              break
            case 'notCountShare':
              text = "You can't use this invite login"
              break
            case 'notLinkReferral':
              text = 'This login cannot be referral'
              break
            default:
              text = this.langStrings?.Errors.unknowError
          }

          runInAction('error while signUp', () => this.addNotification('error', text))
        }
      })
    })
  }

  @computed get isUserLogin() {
    return localStorage.getItem('lesCabinetSignedIn') || this.signedIn || this.sighnedAsAdmin
  }

  @observable token = storage.getStorage('token') || null

  @action signIn = ({ phone, email, login, password }) => {
    const username = phone || email || login
    this.token = null

    this.request(() => {
      backReq('/public/signin', { username: username.toLowerCase(), password })
        .then((res) => {
          if (!res) {
            throw new Error(
              this.langStrings?.Errors.errorNetwork[0] +
                ' ' +
                this.langStrings?.Errors.errorNetwork[1],
            )
          } else {
            if (res.status === 'success' || res.status === 'alreadyAuthenticate') {
              runInAction('correct signed in', () => {
                this.signedIn = true
                this.token = res.data
                storage.setStorage('token', res.data)
              })
              localStorage.setItem('lesCabinetSignedIn', true)
            } else if (res.status === 'lock') {
              runInAction('set status locked on accunt', () => this.setAccountLocked(true))
            } else {
              throw new Error(this.langStrings?.Errors.errorLoginPasswordWrong)
            }
          }
        })
        .then(() => {
          runInAction('getting user data after sighnedIn', () => this.getUserData())
        })
        .catch((e) => {
          runInAction('error while signIn', () => this.addNotification('error', e.message))
          this.setAuthStage(pages.entrance)
        })
    })
  }

  @action signInAsAdmin = async (token) => {
    backReq('/public/signin/admin', {
      token,
    })
      .then((res) => {
        if (res.status === 'success' || res.status === 'alreadyAuthenticate') {
          runInAction('correct signed in', () => {
            this.signedIn = true
            this.token = res.data || token
          })

          storage.setStorage('token', res.data)
          storage.setStorage('lesCabinetSignedIn', true)

          this.addNotification('success', 'Welcome admin!')
          this.setSighnedAsAdmin()
        } else {
          throw new Error(res.status)
        }
      })
      .then(() => {
        runInAction('getting user data after sighnedInAsAdmin', () => this.getUserData())
      })
      .catch((e) => {
        this.addNotification('error', this.langStrings?.Errors?.unknowError || 'Unknowun error')
      })
      .finally(() => this.setAppLoading(false))
  }

  @action signOut = (props) => {
    const callback = props || (() => {})

    this.signedIn = false
    this.token = null

    localStorage.removeItem('token')
    localStorage.removeItem('lesCabinetSignedIn')

    this.setAppStoreToInitial()

    backReq('/private/signout')
      .then(() => callback())
      .then(() => {
        history.push('/')
      })
      .catch((e) => {
        this.addNotification(
          'В процессе выхода из аккаунта ' + (this.userData.login || '') + ' произошла ошибка',
        )
      })
  }

  // ==========================
  // Информация о консультантах
  // ==========================

  @observable consultantList = []
  @action setConsultantList = (consultants) => (this.consultantList = consultants)

  @observable subscriberList = []
  @action setSubscriberList = (subs) => (this.subscriberList = subs)

  // ====================
  // Состояние приложения
  // ====================

  @observable appLoading = false
  @action setAppLoading = (status) => (this.appLoading = status)

  @observable notVerification = false
  @action setNotVerification = (_) => (this.notVerification = true)

  // ====================
  // Состояние приложения
  // ====================

  @observable notifications = []

  @action addNotification = (type, text) => {
    if (this.removeNotificationIntervalId) {
      clearInterval(this.removeNotificationIntervalId)
    }

    this.notifications.push({
      id: Math.floor(Math.random() * 1000000),
      type,
      text,
    })

    this.removeNotificationIntervalId = setInterval((_) => {
      runInAction('remove notification by expire', () => {
        const isRest = this.removeNotification()
        if (!isRest) clearInterval(this.removeNotificationIntervalId)
      })
    }, 5 * 1000)
  }

  @action removeNotification = (_) => {
    this.notifications = this.notifications.slice(1)
    return !!this.notifications.length
  }

  /**
   * @key { string } -> роуты 1го уровня
   * @value { Array } -> Массив с объектами уведомлений
   */
  @computed get notificationMarks() {
    const notificate = this.liveNotificationMark || storage.getStorage('msg')

    return notificate || {}
  }
  @observable liveNotificationMark = null

  @action setNotificationMark = (namespace, value) => {
    if (Array.isArray(value) && namespace === '/buy-shares') {
      value = value.filter((payment, index, arr) => {
        const isExpire = Date.parse(payment.timeoutAT) < Date.now()
        const isDuplicate = arr.findIndex((c) => c._id === payment._id) !== index

        return !isExpire && !isDuplicate
      })
    }

    const newNM = {
      ...this.notificationMarks,
      [namespace]: value,
    }

    this.liveNotificationMark = newNM
    storage.setStorage('msg', newNM)
  }

  @action logger = (msg) => {
    this.isExp &&
      (typeof msg === 'string'
        ? // eslint-disable-next-line no-console
          console.log('%c ' + msg + ' ', 'background: #222; color: #bada55')
        : // eslint-disable-next-line no-console
          console.log(msg))
  }

  // =====================
  // Данные о пользователе
  // =====================

  @observable userData = null

  // Флаг участия в экспериментах
  // Если включен, может давать дополнительную функциональность приложению
  @observable isExp = false

  @observable accountLocked = false
  @action setAccountLocked = (state) => (this.accountLocked = state)

  // Активационный код
  @observable activationTimeoutError = null
  @action setActivationTimeoutError = (err) => (this.activationTimeoutError = err)

  @observable activationResendTimeout = 0
  @observable timeoutTimer = null

  @action setActivationResendTimeout = (seconds) => {
    clearInterval(this.timeoutTimer)
    this.activationResendTimeout = seconds

    this.timeoutTimer = setInterval(() => {
      if (this.activationResendTimeout <= 1) {
        clearInterval(this.timeoutTimer)
        runInAction('remove resend timer by expire', () => {
          this.activationResendTimeout = 0
        })
        return
      }
      runInAction('nerf resend timeout by 1s', () => {
        this.activationResendTimeout -= 1
      })
    }, 1000)
  }

  //=======
  @observable commonLang = 'en'
  @action setLang = (lang) => {
    backReq('/private/user', {
      method: 'update',
      submethod: 'lang',
      lang,
    })

    if (!this.userData) {
      this.userData = {}
    }
    this.userData.lang = lang
    moment.locale(lang)

    this.loadLangStrings(lang)
  }

  @computed get lang() {
    if (!this.userData) {
      return getCookie('lang') || this.commonLang
    }
    return this.userData.lang
  }

  cachedLangs = {}

  //======
  @observable cur = Object.values(CURRENCIES).includes(getCookie('currency'))
    ? getCookie('currency')
    : CURRENCIES.USD

  @action setCur = (cur) => {
    this.cur = cur
    const maxAge = 3600 * 24 * 365
    document.cookie = `currency=${cur}; max-age=${maxAge}`
  }
  //======

  @observable langStrings

  @action setLangStrings = (strings) => {
    this.langStrings = strings
  }

  @action loadLangStrings = (parsedLang) => {
    const langToLoad = ['ru', 'en'].includes(parsedLang) ? parsedLang : 'en'

    runInAction('set commonLang', () => {
      this.commonLang = langToLoad
    })

    if (this.cachedLangs[parsedLang]) {
      this.setLangStrings(this.cachedLangs[parsedLang])
    } else {
      fetch(`/langs/${parsedLang}.json`)
        .then((res) => res.json())
        .then((strings) => {
          runInAction('set LangStrings and cachedLangs', () => {
            this.langStrings = strings
            this.cachedLangs[parsedLang] = strings
          })
        })
    }
  }

  // Получение данных пользователя
  @action getUserData = async (_) => {
    this.setAppLoading(true)

    const res = await backReq('/private/user', { method: 'receive' })

    if (!res || !res.status) {
      this.setAppLoading(false)
      return
    }

    if (res.status === 'success') {
      runInAction('set userData', () => {
        this.userData = { ...res.data }
        this.signedIn = true

        if (isInExpGroup(res.data.login)) {
          this.isExp = true
          this.logger('Вы находитесь на экспериментальном уровне')
          this.logger('Сообщения в консоли видите только вы')
        }

        storage.setStorage('lesCabinetSignedIn', true)
      })

      this.getTotalWidgetData()
      this.getInternalTransfers('notification')

      // Если аккаунт пользователя не активирован, то делаем это
      if (res.data && !(res.data.activated || res.data.isPhoneConfirmed)) {
        this.askForNewActivationCode()
      }

      fetch(`/langs/${this.userData.lang || 'en'}.json`)
        .then((res) => res.json())
        .then((strings) => {
          runInAction('set LangStrings', () => (this.langStrings = strings))
        })
    } else if ((res.status = 'notAuthenticate')) {
      this.setAppLoading(false)
      return
    } else {
      this.addNotification('error', 'НЕ получилось загрузить данные')
    }
    this.setAppLoading(false)
  }

  // Для корректного роутинга держим в переменной состояние регистрации пользователя
  // по сути, res и byteMap - двоичные числа, где каждый бит отвечает за определенное состояние
  // Такой подход делает возможным отслеживание всего состояния регистрации одним вычисляемым значением
  @computed get userStateOfByte() {
    let res = 0

    // if (this.sighnedAsAdmin) {
    //   return 31;
    // }

    if (this.userData === null) {
      return 0
    }

    if (this.signedIn || this.sighnedAsAdmin) {
      res |= byteMap.SIGNED_IN
    }

    if (this.userData.isPhoneConfirmed) {
      res |= byteMap.PHONE_CONFIRMED
    }

    if (this.userData.activated) {
      res |= byteMap.ACTIVATED
    }

    if (!this.userData.accountLocked) {
      res |= byteMap.IS_NOT_LOCKED
    }

    if (this.userData.inTree) {
      res |= byteMap.IN_REFERAL_TREE
    }

    return res
  }

  // Значения для <TotalWidget />
  @observable userSharesCount = 0
  @observable userBalance = 0
  @observable userIncome = 0
  @observable userReferralsCount = 0
  @observable inviter

  // Получить значения для <TotalWidget />
  @action getTotalWidgetData = async (_) => {
    const res = await backReqWithCache('/private/referral', {
      method: 'receive',
    })

    if (res.status === 'success') {
      runInAction('set data from correct widjet', () => {
        this.userSharesCount = res.data.countShare || 0
        this.userBalance = res.data.balance || 0
        this.userIncome = res.data.income || 0
        this.userReferralsCount = res.data.loginListInTree.length || 0
        this.inviter = res.data.loginUserOfInvited
      })
      if (!this.referralTree) {
        runInAction('set initial referal tree', () => {
          this.referralTree = {
            [this.userData.login]: {
              data: res.data,
              child: res.data.loginUserToInvited,
              collapsed: false,
            },
          }
        })
      }
    }
  }

  // ===============
  // Вывод средств
  // ===============

  @observable payoutsHistory = []

  @action getPayoutsHistory = async (_) => {
    const res = await backReq('/private/transfer', {
      method: 'receive',
      submethod: 'all',
      type: 'external',
    })

    if (res.status === 'success') {
      runInAction('create payoutsHistory', () => (this.payoutsHistory = res.data.reverse()))
    }

    return res
  }

  // ===============
  // Overcharge balance
  // ===============
  @observable balanceHistory = []

  @action getBalanceHistory = () => {
    backReqWithCache('/private/overchargeBalance', {
      method: 'receive',
      submethod: 'all',
    }).then(({ status, data }) => {
      if (status === 'success') {
        runInAction('setting balanceHistory', () => {
          this.balanceHistory = groupByDate(
            data.reverse().filter((c) => c.createDate >= '2020-09-21'),
          )
        })
      }
      return data
    })
  }

  // ===============
  // Внутренние переводы
  // ===============

  @observable transfersNotification = []
  @observable transfers = []

  // Получение списка внутренних переводов
  @action getInternalTransfers = async (submethod) => {
    const res = await backReq('/private/transfer', {
      method: 'receive',
      submethod, // all, await, canceled, completed, notification
      type: 'internal',
    })

    if (submethod === 'notification') {
      if (res.status === 'success')
        runInAction(
          'setting transferNotification',
          () => (this.transfersNotification = res.data.reverse()),
        )
      if (res.status === 'notExist')
        runInAction('transferNotification not found', () => (this.transfersNotification = []))
    } else {
      if (res.status === 'success')
        runInAction('setting transferNotification', () => (this.transfers = res.data.reverse()))
    }
  }

  @action cancelTransferRequest = async (idTransferInternal) => {
    this.setAppLoading(true)
    const res = await backReq('/private/transfer', {
      method: 'cancel',
      submethod: 'internal',
      idTransferInternal,
    })
    this.setAppLoading(false)

    switch (res.status) {
      case 'success':
        this.addNotification('success', this.langStrings?.errorTransferCanceled)
        this.getInternalTransfers('all')
        break

      case 'notExist':
        this.addNotification('error', this.langStrings?.errorTransferNotExists)
        break

      default:
        break
    }
  }

  // ===============
  // ReferralTree
  // ===============

  @observable referralTree = undefined

  @action addToReferralTree = ({ login, data }) => {
    const newValue = {
      [login]: {
        data,
        child: data.loginUserToInvited,
        collapsed: true,
      },
    }

    this.referralTree = {
      ...this.referralTree,
      ...newValue,
    }
  }

  @action removeFromReferralTree = (login, parentLogin) => {
    let newTree = { ...this.referralTree }

    newTree[parentLogin] = {
      ...newTree[parentLogin],
      child: newTree[parentLogin].child.filter((c) => c !== login),
    }
    delete newTree[login]
    this.referralTree = newTree
  }

  @action toggleCollapsed = (login) => {
    this.referralTree = {
      ...this.referralTree,
      [login]: {
        ...this.referralTree[login],
        collapsed: !this.referralTree[login].collapsed,
      },
    }
  }

  @action loadReferralTree = (login, parentLogin) => {
    if (this.referralTree && this.referralTree[login] && this.referralTree[login].data) {
      return new Promise((res) => {
        this.calculateLogin(login)
        res(this.referralTree[login].data)
      })
    }

    return this.extendReferralTree(login, parentLogin) // fetch data of partner
      .then((data) => {
        if (!data) return

        data.loginUserToInvited.forEach((partner) => {
          // for each login
          this.loadReferralTree(partner, login)
        })
      })
  }

  @action extendReferralTree = (login, parentLogin) => {
    if (!login) return
    const isRoot = login === this.userData.login

    return backReq('/private/referral', {
      method: 'tree',
      submethod: isRoot ? 'node' : 'search', // nodeOfInvited - получить ноду пригласителя
      login,
    })
      .then(({ status, data }) => {
        if (status === 'success') {
          this.addToReferralTree({ login, data, collapsed: true })
          return data
        }
        this.removeFromReferralTree(login, parentLogin)
        return null
      })
      .catch((e) => {
        this.addNotification(e.message)
      })
  }

  // this function load nothing, it only calculate in existing tree
  @action calculateLogin = (login) => {
    if (!(this.referralTree && this.referralTree[login] && this.referralTree[login].data)) {
      return { investors: 0, partners: 1, fullCalculated: false }
    }

    const rootData = this.referralTree[login].data

    const rootState = {
      investors: +!!rootData.countShare,
      partners: 1,
      fullCalculated: true,
    }

    if (this.referralTree[login].child.length === 0) {
      this.setUserCalculated(login, rootState)
      return rootState
    }

    if (rootData.fullCalculated) {
      return {
        investors: rootData.hasInvestors, // early exit
        partners: rootData.hasPartners, // early exit
        // full: true,
      }
    }

    const res = this.referralTree[login].child.reduce((state, user) => {
      const currentState = this.calculateLogin(user)
      this.setUserCalculated(user, currentState)
      return {
        investors: state.investors + currentState.investors,
        partners: state.partners + currentState.partners,
        fullCalculated: state.fullCalculated && currentState.fullCalculated,
      }
    }, rootState)

    this.setUserCalculated(login, res)
    return res
  }

  @action setUserCalculated = (
    login,
    { investors, partners, fullCalculated = false, loading = false },
  ) => {
    if (login === this.userData.login) return
    if (!this.referralTree[login]) return
    if (this.referralTree[login].data.fullCalculated) return

    this.referralTree[login].data.hasInvestors = investors
    this.referralTree[login].data.hasPartners = partners
    this.referralTree[login].data.fullCalculated = fullCalculated
    this.referralTree[login].data.loading = loading
  }

  @action setUserLoading = (login, loading) => {
    if (login === this.userData.login) return
    if (!this.referralTree[login]) return

    this.referralTree[login].data.loading = loading
  }

  // Страница инвестиций
  @observable cachedInvestments = {
    actual: false,
    installments: [],
    contest: [],
    block: [],
    gift: [],
  }

  @action setActualCahedInvestments = (data) => {
    this.cachedInvestments = {
      actual: true,
      ...data,
    }
  }

  @action fetchInvestments = async () => {
    if (this.appLoading) return

    const { data, status } = await backReq('/private/investment/history')

    if (status !== 'success') {
      this.addNotification(
        'error',
        'This page can not be downloaded. Please contact us! Error code - ' + status,
      )
    }

    this.setActualCahedInvestments(data)
  }

  @observable cachedPayments = {}

  @action fetchPayments = async (idInstallment) => {
    if (this.cachedPayments[idInstallment]) {
      return this.cachedPayments[idInstallment]
    }

    const res = await backReq('/private/pay', {
      method: 'receive',
      submethod: 'completed', // all, await, completed, canceled
      type: 'installment',
      idInstallment,
    })

    if (res.status === 'success') {
      runInAction('caching payments ', () => {
        this.cachedPayments[idInstallment] = res.data.reverse()
      })
      return this.cachedPayments[idInstallment]
    }
  }

  @computed get investments() {
    if (!this.cachedInvestments.actual) {
      this.fetchInvestments()
    }

    return this.cachedInvestments
  }

  // Приводим стор в изначальное состояние
  @action setAppStoreToInitial = (redirectPage) => {
    this.AuthStage = redirectPage ? redirectPage : pages.entrance
    this.documents = []
    this.notifications = []
    this.userData = null
    this.activationTimeoutError = null
    this.activationResendTimeout = 0
    this.langStrings = null
    this.userSharesCount = 0
    this.userBalance = 0
    this.userIncome = 0
    this.userReferralsCount = 0
    this.referralTree = undefined
    this.payoutsHistory = []
    this.transfersNotification = []
    this.transfers = []
    this.isSidebarExpanded = true
    this.currentBreadcrumbs = ['Dashboard']
    this.packages = null
    this.installments = []
    this.installmentsHistory = []
    this.cachedPayments = {}
    this.cachedInvestments = {}

    if (localStorage.getItem('NOT_DISTURBED_NAME')) {
      localStorage.clear()
      localStorage.setItem('NOT_DISTURBED_NAME', 'true')
    } else {
      localStorage.clear()
    }
  }

  @action destroyLoggedInState = (_) => (this.signedIn = false)

  // =================
  // Платежные системы
  // =================
  @observable techWorks = {}

  // Для выключения возможности пользоваться системой нужно добавить в платежку outOfService: true
  @computed get paymentSystems() {
    const paymentsForRussian = [
      // { title: "PayMaster", psCode: "payMaster" },
      // { title: "Robokassa", psCode: "roboKassa" },
      // { title: "Yandex", psCode: "yandex" },
      // { title: 'Cryptonator', psCode: 'cryptonator' },
      // { title: "Advcash", psCode: "advcash" },
      this.isExp && { title: 'Payeer', psCode: 'payeer' },
      this.isExp && { title: 'Perfect Money', psCode: 'perfectmoney' },
      { title: 'Binance', psCode: 'binanceService' },
      { title: 'Your balance', psCode: 'internal', balance: '0' },
      { title: 'Card USD', psCode: 'theMerchMoney' },
      { title: 'Card RUB', psCode: 'theMerchMoneyRub' },
    ]

    const paymentsForOther = [
      // { title: "PayMaster", psCode: "payMaster" },
      // { title: "Robokassa", psCode: "roboKassa" },
      // { title: "Yandex", psCode: "yandex" },
      // { title: 'Cryptonator', psCode: 'cryptonator' },
      // { title: "Advcash", psCode: "advcash" },
      this.isExp && { title: 'Payeer', psCode: 'payeer' },
      this.isExp && { title: 'Perfect Money', psCode: 'perfectmoney' },
      { title: 'Binance', psCode: 'binanceService' },
      { title: 'Your balance', psCode: 'internal', balance: '0' },
      { title: 'Card USD', psCode: 'theMerchMoney' },
      { title: 'Card RUB', psCode: 'theMerchMoneyRub' },
    ]

    const isRussian = (window.navigator.language || window.navigator.userLanguage) === 'ru-RU'

    let payments = isRussian ? paymentsForRussian : paymentsForOther

    return payments
      .filter((c) => Boolean(c) && !this.techWorks[c.psCode])
      .map((c) => ({
        ...c,
      }))
  }

  // ==================
  // Состояние сайдбара
  // ==================

  @observable isSidebarExpanded = true
  @action setIsSidebarExpanded = (isExpanded) => {
    this.isSidebarExpanded = isExpanded

    if (window.innerWidth <= 850) {
      if (isExpanded) {
        window.document.querySelector('body').style.overflowY = 'hidden'
      } else {
        window.document.querySelector('body').style.overflowY = 'auto'
      }
    }
  }

  // ==============
  // Хлебные крошки
  // ==============

  @observable currentBreadcrumbs = ['Dashboard']

  @action setCurrentBreadcrumbs = (first, second) => {
    let bc = [first]
    second && bc.push(second)
    this.currentBreadcrumbs = bc
  }

  // =================
  // Получение пакетов
  // =================

  @observable packages = null

  @action setPackages = async (packages) => {
    const course = this.xchangeCourse

    this.packages = packages
      .filter((p) => p.available)
      .sort((a, b) => a.cost - b.cost)
      .map((c) => ({
        ...c,
        costUSD: c.cost,
        costShareUSD: c.costShare,
      }))
  }

  // =================
  // Рассрочки
  // =================

  @observable installments = []
  @action setInstallments = (list) => (this.installments = list)

  // =================
  // Документы (EDK)
  // =================

  @observable documents = []
  @action setDocuments = (docs) => (this.documents = docs)

  @action fetchDocuments = async () => {
    const { data, status } = await backReq('/private/edk', {
      method: 'receive',
      submethod: 'sign',
    }) // all, sign

    if (status === 'notExist') {
      return
    }

    if (status !== 'success') {
      this.addNotification('error', this.langStrings?.Errors.unknowError)
      return
    }

    let docsObj = {}

    data.forEach((doc) => {
      const newObj = {
        fileName: doc.fileName,
        createDate: doc.createDate,
        subtype: doc.subtype,
        idEdk: doc.idEdk,
        url: doc.url,
      }

      if (doc.subtype === 'block' || doc.subtype === 'contest') {
        docsObj[doc.createDate] = newObj
      } else if (doc.subtype === 'installment') {
        Array.isArray(docsObj[doc.idInstallment])
          ? (docsObj[doc.idInstallment] = [...docsObj[doc.idInstallment], newObj])
          : (docsObj[doc.idInstallment] = [newObj])
      }
    })

    let docsArr = []

    Object.keys(docsObj).forEach((key) => {
      docsArr.push(docsObj[key])
    })

    this.setDocuments(docsArr)
  }

  @computed get getDocuments() {
    if (this.documents.length) {
      return this.documents
    } else {
      this.fetchDocuments()
      return []
    }
  }

  // =======================
  // Работа с оплатами пакетов
  // ========================
  @action fetchPackages = async () => {
    const { status, data } = await backReqWithCache('/private/block', {
      method: 'receive',
      submethod: 'all',
    })

    this.fetchCryptoCurrencies()

    if (status !== 'success') {
      this.addNotification('error', 'Не удалось загрузить данные о доступных пакетах')
      return
    }

    this.setPackages(data)
  }

  @observable allCryptoCurrencies = []
  @computed get availableCryptocurrencies() {
    const sumForPay = this.currentPackage.realCost - this.payObject.amount
    this.logger('Сумма для оплаты всего = ' + this.currentPackage.realCost)
    this.logger('Сумма для оплаты баланс = ' + this.payObject.amount)
    this.logger('Сумма для оплаты система = ' + sumForPay)

    const isAvailable = {
      BTC: () => sumForPay / this.xchangeCourse >= 100,
    }

    return this.allCryptoCurrencies.filter((c) => (isAvailable[c.symbol] || (() => true))())
  }

  @action getCryptoStatus = async () => {
    const { status } = await backReq('/private/siteSettings/cripto-pay/status', {
      method: 'receive',
      submethod: 'all',
    })
    if (status !== 'success') {
      runInAction('Бинанс временно недоступен - выключаем', () => {
        this.techWorks['binanceService'] = true
      })
    }

    this.fetchCryptoCurrencies()
  }

  @action fetchCryptoCurrencies = async () => {
    return backReq('/private/siteSettings/cripto-pay/currency', {
      method: 'receive',
      submethod: 'all',
    })
      .then((data) => {
        if (data.status !== 'success') {
          runInAction('Бинанс временно недоступен - выключаем', () => {
            this.techWorks['binanceService'] = true
          })
        }
        return data
      })
      .then((data) => {
        this.logger(data)
        runInAction('Запоминаем все криптовалюты', () => {
          this.allCryptoCurrencies = data.data
        })
      })
      .catch((e) => {
        this.logger(e)
        runInAction('Бинанс временно недоступен - выключаем', () => {
          this.techWorks['binanceService'] = true
        })
      })
  }

  @observable payObject = {
    method: 'add', // Для создания платежей всегда add
    submethod: null, // psCode - Идентификатор платежной системы
    type: 'whole', // whole для платежа c одной платежной системы или combined
    subtype: 'block', // block для разового платежа или installments для рассрочки
    idBlock: null, // идентификатор выбранного пакета
    currencyId: null, // для криптоплатежей - выбранная валюта
    amount: 0, // для комбинированных платежей - сумма с баланса
  }

  @action setPropertiesOfPayObject = (propertiesObj) => {
    const msg = 'Задаем свойство ' + Object.keys(propertiesObj)

    runInAction(
      msg,
      () =>
        (this.payObject = {
          ...this.payObject,
          ...propertiesObj,
        }),
    )

    this.logger(msg)
    this.logger(toJS(this.payObject))
  }

  @observable currentCryptoPay = null

  @action createPayment = (additionalProps) =>
    backReq('/private/pay', {
      ...this.payObject,
      ...additionalProps,
    })

  @observable cachedCurrentPackage = {}
  @action saveCurrentPackage = (cPackage) => {
    this.cachedCurrentPackage = cPackage
  }
  // TODO: переделать packages для взятия пакета по id
  @computed get currentPackage() {
    if (this.payObject.idBlock === null) return this.cachedCurrentPackage
    const current = this.packages.find((c) => c._id === this.payObject.idBlock)

    return {
      ...current,
      realCost: (current?.cost || Infinity) / (this.payObject.subtype === 'installment' ? 10 : 1),
    }
  }
}

// Создаем и экспортируем стор
const appStore = new AppModel()
window.getAppStore = (_) => toJS(appStore)
window.getRawAppStore = (_) => appStore
window.appStore = appStore

export default appStore

const appContext = createContext(appStore)
export { appContext }
