import React from 'react'
import { NavLink } from 'react-router-dom'

import classes from './NavLink.module.css'

const Link = ({
  to,
  title,
  iconComponent,
  isExpanded,
  isExternal,
  notificate,
  ...others
}) => {
  const linkProps = {
    className: isExpanded
      ? classes.link
      : classes.link + ' ' + classes.shrinked,
    'data-title': title,
    ...others,
  }
  const content = (
    <>
      <div className={classes.icon}>{iconComponent}</div>
      {isExpanded && <div className={classes.title}>{title}</div>}
      {!!notificate.length && (
        <div className={classes.notificate}>{notificate.length}</div>
      )}
    </>
  )

  return isExternal ? (
    <a href={to} {...linkProps}>
      {content}
    </a>
  ) : (
    <NavLink to={to} {...{ ...linkProps, activeClassName: classes.active }}>
      {content}
    </NavLink>
  )
}

export default Link
